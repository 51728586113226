import { Switch, Route } from 'react-router-dom';
import NavbarWrapper from './components/Navbar';
import EDCTTable from './components/EDCTTable';
import TMUWrapper from './components/TMUWrapper';
import EDCTLadderWrapper from './components/EDCTLadderWrapper';
import ConfigLadders from './components/ConfigLadders';
import ConfigStreams from './components/ConfigStreams';
import ConfigStars from './components/ConfigStars';
import Privacy from './components/Privacy';
import NotFoundPage from './components/NotFoundPage';
import HomePage from './components/HomePage'
import Login from './components/Login';
import ConfigFacilities from './components/ConfigFacilities';
import ConfigAirports from './components/ConfigAirports';
import Profile from './components/Profile';
import Status from './components/Status';
import ConfigRestrictions from './components/ConfigRestrictions';
import FacilityAdmin from './components/FacilityAdmin';
import ConfigUsers from './components/ConfigUsers';
import AirportAdmin from './components/AirportAdmin';


// TODO add error catch-all component route
function App() {
  return (
    <div>
      <NavbarWrapper />
      <Switch>
        <Route path="/loginverify" component={Login} />
        <Route path="/profile" component={Profile} />
        <Route path="/status" component={Status} />
        <Route path="/privacy" component={Privacy} />
        <Route path="/tmu/:icao" component={TMUWrapper} />
        <Route path="/edct/:icao" component={EDCTLadderWrapper}/>
        <Route path="/configstreams" component={ConfigStreams}/>
        <Route path="/configladders" component={ConfigLadders}/>
        <Route path="/configstars" component={ConfigStars}/>
        <Route path="/configusers" component={ConfigUsers}/>
        <Route path="/configfacilities" component={ConfigFacilities}/>
        <Route path="/configairports" component={ConfigAirports}/>
        <Route path="/configrestrictions" component={ConfigRestrictions}/>
        <Route path="/clearance/:airport" component={EDCTTable} /> 
        <Route path="/facilityadmin/:facilityID" component={FacilityAdmin} />
        <Route path="/airportadmin/:airportID" component={AirportAdmin} /> 

        <Route exact path="/" component={HomePage} />
        <Route path="*" component={NotFoundPage} />
      </Switch>
    </div>
  );
}

export default App;
