 // eslint-disable-next-line no-unused-vars
import { useEffect, useState } from 'react';
 // eslint-disable-next-line no-unused-vars
import { Container, Button, ButtonGroup, Modal, Form, Row, Col } from 'react-bootstrap';

const CreateStarModal = (props) => {

  const [submitOk, setSubmitOk] = useState(false);
  const [ident, setIdent] = useState("");
  const [merge, setMerge] = useState("");
  const [trans, setTrans] = useState("");
  const [airport, setAirport] = useState("ZZZZ");
  const [airports, setAirports] = useState([]);


  const handleSubmit = (e) => {
    e.preventDefault();
    props.createStar(e.target.star_ident.value, e.target.airport_icao.value, e.target.merge_fix.value, e.target.transition_fix.value);
  }

  const show = () =>{
    if(props.airports !== undefined) { setAirports(props.airports)}
    
    

  }
  

  const dataChanged = (e) => {
    //console.log(e);
    if(e.target.name==="star_ident") 
    {
      setIdent(e.target.value);
      setSubmitOk(e.target.value.length>0 && merge.length>0 && trans.length>0 && airport.length>0);
    }

    if(e.target.name==="merge_fix") 
    {
      setMerge(e.target.value);
      setSubmitOk(e.target.value.length>0 && ident.length>0 && trans.length>0 && airport.length>0);
    }

    if(e.target.name==="transition_fix") {
      setTrans(e.target.value);
      setSubmitOk(e.target.value.length>0 && ident.length>0 && merge.length>0 && airport.length>0);
    }
    

  }


  useEffect(() => {
    setSubmitOk(false);

  },[])

  return (
    <Modal onShow={show} show={props.show_func} onHide={props.close_func}>
      <Modal.Header closeButton>
        <Modal.Title>Add STAR</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>STAR Identifier</Form.Label>
            <Form.Control name="star_ident" type="text" maxLength="5" onChange={dataChanged} placeholder="e.g. HLYWD" />
          </Form.Group>
          <Form.Group>
            <Form.Label>Airport ICAO</Form.Label>
            <Form.Control as="select" name="airport_icao" value={airport} onChange={e => setAirport(e.target.value)}>
              <option key="ZZZ" value="ZZZ">---Select One---</option>
              {airports.map((f, idx) => {
                return (<option key={idx} value={f.icao} >{f.icao}</option>)
              })}
            </Form.Control>
          </Form.Group>

          <Form.Group>
            <Form.Label>Merge Fix Identifier</Form.Label>
            <Form.Control name="merge_fix" type="text" maxLength="5" onChange={dataChanged} placeholder="e.g. AVATR" />
          </Form.Group>
          <Form.Group>
            <Form.Label>Transition Fix Identifier</Form.Label>
            <Form.Control name="transition_fix" type="text" maxLength="5" onChange={dataChanged} placeholder="e.g. ESTWD" />
          </Form.Group>
          <Button disabled={!submitOk} variant="primary" type="submit">
            Save
          </Button>
        </Form>
        </Modal.Body>
    </Modal>
  );
}

export default CreateStarModal;