import {Nav, Navbar, NavDropdown } from 'react-bootstrap';
import Clock from 'react-live-clock';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import logo from '../calflow.png'
import AuthService from '../services/AuthService';
import APIService from '../services/APIService';
import LoginModal from './LoginModal';


const NavbarWrapper = () => {

  const [showLogin, setShowLogin] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [userName, setUserName] = useState("");
  const [adminLadders, setAdminLadders] = useState(false);
  const [adminAirports, setAdminAirports] = useState(false);
  const [adminStars, setAdminStars] = useState(false);
  const [adminUsers, setAdminUsers] = useState(false);
  const [adminEDCT, setAdminEDCT] = useState(false);
  const [adminFacilities, setAdminFacilities] = useState(false);
  const [adminRestrictions, setAdminRestrictions] = useState(false);
  const [ladders, setLadders] = useState([]);
  const [env, setEnv] = useState("");
 

  const location = useLocation();

  const sortLadder = (a,b) => {
    if(a.facility < b.facility){
      return -1;  
  }else if(a.facility > b.facility){
      return 1;
  }else{
      if(a.icao < b.icao){
         return -1
      }else if(a.icao > b.icao){
        return 1;
      }else{
        return 0;
      }
  }
  }

  


  const getLadders = async () => {
    try {
      const res = await APIService.apiGET(`${process.env.REACT_APP_API_URL}/api/config/ladders`);
      let out = [];
      let ids = new Set();
      
      res.data.forEach((item) => {
        let i = {};

        i["airport"]=item["airport"]["name"];
        i["facility"]=item["airport"]["facility_id"];
        i["icao"]=item["airport"]["icao"];
        if(!ids.has(i["icao"])) {
          ids.add(i["icao"]);
          out.push(i);

        }
        


      })

      out.sort(sortLadder);
      


      //console.log(out);

      setLadders(out);

    } catch (err) {

      console.log(err);
    }
    
  };
  
  const returnAirportName = (icao) => {
    switch (icao) {
      case "klax":
        return "Los Angeles Intl"
      case "klas":
        return "Las Vegas Intl"
      case "ksan":
        return "San Diego Intl"
      case "ksfo":
        return "San Francisco Intl"
      case "koak":
        return "Oakland Intl"
      case "ksjc":
        return "San Jose Intl"
      case "kphx":
        return "Phoenix Sky Harbor Intl"
      case "kden":
        return "Denver Intl"
      case "kpdx":
        return "Portland Intl"
      case "ksea":
        return "Seattle Intl"
      case "panc":
        return "Anchorage Intl"
      default:
        if(icao!==undefined)  return icao.toUpperCase().substring(1,4);
        return "";
    }
  }
  const login = () => {

    AuthService.login();
  };

  const loginComplete = (cid) => {    
    AuthService.loginLocal(cid).then(() => {
      updatePermissions()
    }
    );
    
  };

  const logout = () => {
    AuthService.logout().then(() => {
      updatePermissions();
      window.location="/";
    }
    );
    
  }

  const updatePermissions = () => {
    setLoggedIn(AuthService.loggedIn());
    setUserName(AuthService.userName());
    setAdminAirports(AuthService.isFacilityAdmin());
    setAdminEDCT(AuthService.isFacilityAdmin());
    setAdminLadders(AuthService.isFacilityAdmin());
    setAdminStars(AuthService.isFacilityAdmin());
    setAdminFacilities(AuthService.isFacilityAdmin());
    setAdminRestrictions(AuthService.isFacilityTMU());
    setAdminUsers(AuthService.hasPermission('admin.users'));



    
  }

  const generateTitle = (path) => {
    let airport_name = returnAirportName(path.split("/")[2]);


    if (path.includes("tmu")) {
      return airport_name + " TMU"
    } else if (path.includes("edct")) {
      return airport_name + " EDCT"
    } else if (path.includes("clearance")) {
      return airport_name + " Clearance"
    }
  };

  const hasAdmin = () => {
    return adminAirports || adminEDCT || adminFacilities || adminLadders || adminStars || adminRestrictions || adminUsers;
  };

  useEffect(() => {
    
    updatePermissions();  
    getLadders();
    setEnv(process.env.REACT_APP_ENV); 
    
  },[])

  const show_login = () => {
    setShowLogin(true);
  }  

  const close_login = () => {
    setShowLogin(false)
  }

  let prev = "";

  return (
    <div>
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" fixed="top">
      <Navbar.Brand href="/">
        <img
          alt=""
          src={logo}
          width="25"
          height="30"
          className="ms-3 d-inline-block align-top"
        />{' '}
        CalFLOW
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="me-auto">
          { loggedIn && ( <>
              <NavDropdown title="TMU Ladders" id="collasible-nav-dropdown">
              {
                  

                  ladders.map((ladder, idx) => {
                  if(prev==="") {
                    prev = ladder["facility"];
                    return ( <NavDropdown.Item key={idx} href={'/tmu/'+ladder["icao"].toLowerCase()}>{  ladder["airport"]}</NavDropdown.Item> )

                  }
                  else if(prev===ladder["facility"]) {
                    prev = ladder["facility"];
                    return ( <NavDropdown.Item key={idx} href={'/tmu/'+ladder["icao"].toLowerCase()}>{  ladder["airport"]}</NavDropdown.Item> )

                  }
                  else
                  {
                    prev = ladder["facility"];
                    return ( <><NavDropdown.Divider /> <NavDropdown.Item key={idx} href={'/tmu/'+ladder["icao"].toLowerCase()}>{  ladder["airport"]}</NavDropdown.Item></> )
                  }
                  
                  
                    
                  
                  })


              }


            </NavDropdown>
            <Nav.Link target="_blank" href="https://ids.laartcc.org">IDS</Nav.Link>
            <NavDropdown title="Flow Ladders" id="collasible-nav-dropdown">
              <NavDropdown.Item href="/edct/klax">Los Angeles</NavDropdown.Item>
              <NavDropdown.Item href="/edct/klas">Las Vegas</NavDropdown.Item>
              <NavDropdown.Item href="/edct/ksan">San Diego</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/edct/ksfo">San Francisco</NavDropdown.Item>
              <NavDropdown.Item href="/edct/koak">Oakland</NavDropdown.Item>
              <NavDropdown.Item href="/edct/ksjc">San Jose</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/edct/kphx">Phoenix</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/edct/kden">Denver</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Flow Dashboard" id="collasible-nav-dropdown">
              <NavDropdown.Item href="/clearance/klax">Los Angeles Clearance</NavDropdown.Item>
              <NavDropdown.Item href="/clearance/klas">Las Vegas Clearance</NavDropdown.Item>
              <NavDropdown.Item href="/clearance/ksan">San Diego Clearance</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/clearance/ksfo">San Francisco Clearance</NavDropdown.Item>
              <NavDropdown.Item href="/clearance/koak">Oakland Clearance</NavDropdown.Item>
              <NavDropdown.Item href="/clearance/ksjc">San Jose Clearance</NavDropdown.Item>
            </NavDropdown>
          </>

          )}
          
          <Navbar.Text>
            <span className="zulu-clock mono-font"><Clock format={'HH:mm:ss'} ticking={true} timezone={'GMT'} /></span>
            {/* <span className="local-clock mono-font"><Clock format={'HH:mm:ss'} ticking={true} timezone={'US/Pacific'} /> Local</span> */}
          </Navbar.Text>
          <Navbar.Text>
            <span className="navbar-title">{generateTitle(location.pathname)}</span>
          </Navbar.Text>
        </Nav>
        <Nav>
          {
            hasAdmin() && (
              <NavDropdown title="Config" id="collasible-nav-dropdown">

                { adminEDCT && ( <NavDropdown.Item href="/configstreams">EDCT</NavDropdown.Item>  )}
                { adminLadders && ( <NavDropdown.Item href="/configladders">TMU Ladder</NavDropdown.Item> )}
                { adminStars && (<NavDropdown.Item href="/configstars">STAR</NavDropdown.Item> )}
                { adminFacilities && (<NavDropdown.Item href="/configfacilities">Facilities</NavDropdown.Item> )}
                { adminAirports && (<NavDropdown.Item href="/configairports">Airports</NavDropdown.Item> )}    
                { adminRestrictions && (<NavDropdown.Item href="/configrestrictions">Restrictions</NavDropdown.Item> )} 
                { adminUsers && (<NavDropdown.Item href="/configusers">Users</NavDropdown.Item> )}        
                
               
              </NavDropdown>

            )}
          
          <Nav.Link target="_blank" href="https://www.laartcc.org">ZLA</Nav.Link>
          <Nav.Link href="/privacy">Privacy Policy</Nav.Link>  
          {
            loggedIn ? (
              <NavDropdown align="end" title={userName} id="collasible-nav-dropdown">
                {/* <NavDropdown.Item href="/status">Status</NavDropdown.Item> */}
                <NavDropdown.Item href="/profile">Profile</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={logout}>Logout</NavDropdown.Item>
              </NavDropdown>             
            ) : env==="local" ? 
            (
                <Nav.Link onClick={show_login}>Login</Nav.Link> 
            )            
            :

            (
              <Nav.Link onClick={login}>Login</Nav.Link> 
              
              
            )

            

           
          }  
          
            
        </Nav>
      </Navbar.Collapse>
    </Navbar>
    <LoginModal show_func={showLogin} close_func={close_login} loginFunc={loginComplete} />

          </div>
    
  );
};

export default NavbarWrapper;