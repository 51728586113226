import { useEffect } from 'react';
import AuthService from '../services/AuthService';


const Login = (props) => {   
    useEffect( () => {
        let parms = new URLSearchParams(props.location.search);
        let code = parms.get("code");
        AuthService.verify(code);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return (
        <></>

    )
}

export default Login;
