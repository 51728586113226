import { useEffect, useState } from 'react';
import { Container, Row, Col, Table ,ButtonGroup, Button, Form} from 'react-bootstrap'
import { Select2 } from "select2-react-component";
import 'select2-component/dist/select2.min.css';
import CreateStreamModal from './CreateStreamModal';
import AuthService from '../services/AuthService';
import APIService from '../services/APIService';

const ConfigStreams = (props) => {
  const [configs, setConfigs] = useState([]);
  const [allMergeFixes, setAllMergeFixes] = useState([]);
  const [configMergeFixes, setConfigMergeFixes] = useState([]);
  const [editMode, setEditMode] = useState([]);
  
  const [showModal, setShowModal] = useState(false);
  const [departure, setDeparture] = useState("");
  const [arrival, setArrival] = useState("");
  const [timeMins, setTimeMins] = useState(0);
  const [timeSecs, setTimeSecs] = useState(0);
  const [activeFixes, setActiveFixes] = useState([]);
  const [facilities, setFacilities] = useState([]);
  
  const enableEditMode = (idx) => {
    setDeparture(configs[idx].departure.icao);
    setArrival(configs[idx].arrival.icao);

    let time = configs[idx].ete.split(":");

    setTimeMins(time[0]);
    setTimeSecs(time[1]);
    
    setActiveFixes(configs[idx].fixes.map((fix)=>{ return fix.id}))
    
    editMode[idx] = true;
    setEditMode([...editMode]);
  };

  const popupMergeFixes = () => {

    return allMergeFixes;

  }
  
  const disableEditMode = (idx) => {
    editMode[idx] = false;
    setEditMode([...editMode]);
  }

  const saveEdits = async (idx) => {

    let time = String(timeMins)+":"+String(timeSecs);
    console.log(departure,arrival,time,activeFixes);



    
    try {
      // eslint-disable-next-line no-unused-vars
      const res = await APIService.apiPOST(`${process.env.REACT_APP_API_URL}/api/config/edcts/update`, {
        "id": configs[idx]["id"],
        "departure": departure,
        "arrival": arrival,
        "ete": time,
        "fixes": activeFixes
      });
  
      disableEditMode(idx);
      getEdctConfigs();


    }
    catch (err) {
      console.log(err.response);
    }
    
  }

  const createStream = async (departure, arrival, ete, fixes) => {    
    try {
      const res = await APIService.apiPOST(`${process.env.REACT_APP_API_URL}/api/config/edcts/update`, {      
        "departure": departure,
        "arrival": arrival,
        "ete": ete,
        "fixes": fixes
      });

      

      if(res.status===204) {
        close_modal();
        getEdctConfigs();

      }
    }
    catch (err) {
      console.log(err);
      console.log(err.response.status);
      if(err.response.status===401) {
        console.log("refreshing?");
        await AuthService.refreshTokens();  
      }
      else {
        console.log(err);
      }          
    }  
  

    
  }

  const getEdctConfigs = async () => {
    try {
      const res = await APIService.apiGET(`${process.env.REACT_APP_API_URL}/api/config/edcts`);
      let init_edit_mode = [];
      let init_merge = [];
      let out = [];

      res.data.forEach((item) => {
        if(AuthService.hasPermission("facility."+item["arrival"]["facility_id"]+".admin") || AuthService.hasPermission("admin.edct_config")) {
          init_edit_mode.push(false)
          init_merge.push(item.fixes.map((fix)=>{ return fix.id}));
          out.push(item);
        }


        
      });
      setEditMode(init_edit_mode);
      setConfigMergeFixes(init_merge);
      setConfigs(out);

    } catch (err) {

      console.log(err);
    }
    
  };

  const sortMerge = (a,b) => {
      return  (a.label > b.label) ? 1 : -1
  }

  const getMergeFixes = async () => {
    try {
      const res = await APIService.apiGET(`${process.env.REACT_APP_API_URL}/api/config/stars`);
      let stars = res.data;
      let fixes = new Set();
      let fixIDs = new Set();
  
      // eslint-disable-next-line array-callback-return
      stars.map((item,idx) => {      
        if(!fixIDs.has(item.fix.id)) {
          fixIDs.add(item.fix.id);      
          fixes.add( { value: item.fix.id, label: item.fix.name} );
        }         
      });
      
      setAllMergeFixes(Array.from(fixes).sort(sortMerge));

    }
    catch (err) {
      console.log("Merge Fix error?");
      console.log(err);
    }
    
  };

  const getFacilities = async () => {
    try { 
      const res = await APIService.apiGET(`${process.env.REACT_APP_API_URL}/api/config/facilities`);   
      let out = [];
      res.data.forEach((f) => {
        if(AuthService.hasPermission("facility."+f["id"]+".admin") || AuthService.hasPermission("admin.stars")) {
            
          out.push(f);
        }
      })
      setFacilities(out);
    }
    catch (err) {
      console.log(err.response.status);
      if(err.response.status===401) {
        console.log("refreshing?");
        await AuthService.refreshTokens();  
      }
      else {
        console.log(err);
      }          
    }     
  }

  
  const deleteConfig = async (idx) => {
    
    const r = window.confirm("Do you really want to delete "+configs[idx].departure.icao+" to "+configs[idx].arrival.icao+"?"); 
        if(r === true) {
          //eslint-disable-next-line no-unused-vars
          const res = await APIService.apiPOST(`${process.env.REACT_APP_API_URL}/api/config/edcts/delete`, {
            "id": configs[idx].id
          });
      
          disableEditMode(idx);
          getEdctConfigs();
          
        }    
  }

  const create_modal = () => {
    
    setShowModal(true);
}

const close_modal = () => {
    setShowModal(false)
} 

const onLoad = async () => {
  
  await getMergeFixes();
  await getEdctConfigs();
  await getFacilities();


}

  useEffect(() => {
    onLoad();
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container className="mono">
      <Row>
          <Col>
            <h2 style={{color: "white", marginTop:"10px"}}>EDCT Configuration</h2>
          </Col>
          <Col style={{textAlign:"right"}}>{ ( facilities.length>0 || AuthService.hasPermission("admin.edct_config")) && (
              <Button variant="primary" style={{marginTop: "10px"}} onClick={create_modal}>Add Stream</Button>
          )}            
          </Col>
        </Row>

      
      <Row>
        <Col>
          <Table striped bordered hover variant="dark">
            <thead>
              <tr>
                <th>Departure</th>
                <th>Arrival</th>
                <th>Time to Merge Fix</th>
                <th>Streams</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {configs.map((cfg, idx) => {

                if (editMode[idx]) {
                  return (
                    <tr key={idx}>
                      <td><Form.Control type="text" placeholder="Departure" defaultValue={departure} onChange={e => setDeparture(e.target.value)}/></td>
                      <td><Form.Control type="text" placeholder="Arrival" defaultValue={arrival} onChange={e => setArrival(e.target.value)}/></td>
                      <td><Row className="d-flex m-0" ><Col><Form.Control type="number" placeholder="0" defaultValue={timeMins} onChange={e => setTimeMins(e.target.value)}/></Col>:<Col><Form.Control className="ml-0 mr-auto" type="number" placeholder="0" defaultValue={timeSecs} min="0" max="59" onChange={e => setTimeSecs(e.target.value)}/></Col><Col></Col></Row></td>
                      <td><div className="stream-cfg"><Select2 multiple="true" value={activeFixes} data={allMergeFixes} update={value => setActiveFixes(value)} ></Select2></div></td>
                      <td>
                      <ButtonGroup aria-label="save group">
                        <Button variant="success" size="sm" onClick={() => saveEdits(idx)}>Save</Button>{' '}
                        <Button variant="warning" size="sm" onClick={() => disableEditMode(idx)}>Cancel</Button>
                      </ButtonGroup>
                      </td>
                    </tr>
                  )
                } else {
                  return (
                
                  <tr key={idx}>
                    <td>{cfg["departure"]["icao"]}</td>
                    <td>{cfg["arrival"]["icao"]}</td>
                    <td>{cfg["ete"]}</td>
                    <td ><div className="stream-cfg"><Select2 disabled="true" multiple="true" value={configMergeFixes[idx]} data={allMergeFixes} ></Select2></div></td>
                    <td><ButtonGroup aria-label="edit group">
                          <Button variant="warning" size="sm" onClick={() => enableEditMode(idx)}>Edit</Button> 
                          <Button variant="danger" size="sm" onClick={() => deleteConfig(idx)}>Delete</Button>
                        </ButtonGroup></td>
                  </tr>
                )
              }})}
            
            </tbody>
          </Table>
        </Col>
      </Row>
      <CreateStreamModal show_func={showModal} close_func={close_modal} mergeFixes={popupMergeFixes} createStream={(d, a, e, f) => createStream(d, a, e, f)}/>
    </Container>
  )
}

export default ConfigStreams;
