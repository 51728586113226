// eslint-disable-next-line no-unused-vars
import { useEffect, useState } from 'react';
 // eslint-disable-next-line no-unused-vars
import { Container, Button, ButtonGroup, Modal, Form, Row, Col, ToggleButton } from 'react-bootstrap';
import APIService from '../services/APIService';


const EDCTBumpModal = (props) => {

  const [submitOk, setSubmitOk] = useState(true);
  const [airports, setAirports] = useState([]);

  const [selectedAirport, setSelectedAirport] = useState("ALL");
  const [time, setTime] = useState("2");

  

  const handleSubmit = (e) => {
    setSubmitOk(false);
    
    async function doCall() {
      try {
        // eslint-disable-next-line no-unused-vars
        const res = await APIService.apiGET(`${process.env.REACT_APP_API_URL}/api/edct/bump?airport=${props.origin}&arrival=${selectedAirport}&minutes=${time}`);
        props.close_func();
        setSubmitOk(true);

      }
      catch (error) {
        setSubmitOk(true);

      }
    }
    
    e.preventDefault();
    doCall();
  }

  const show= ()=> {
    setSubmitOk(true);
    let temAirports = ['ALL'];    
    temAirports.push( ...props.airports);
    setAirports(temAirports);
    
  }

  return (
    <Modal show={props.show_func} onShow={show} onHide={props.close_func}>
      <Modal.Header closeButton>
        <Modal.Title>Bump</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        Bumping adds time to ALL aircraft based on the parameters you select.. once you Bump, this CANNOT be undone.. use with caution!
        <Form onSubmit={handleSubmit}>
          
      <hr />       

        <Form.Group controlId="airportSelect">
            <Form.Label>Airport</Form.Label>
            <Form.Control as="select" onChange={e => setSelectedAirport(e.target.value)}>
              {         
                  airports.map((item,index) =>{
                    return (
                      <option key={index}>{item}</option>
                    )  
                  })   
              }

            </Form.Control>

        </Form.Group>

        <Form.Group>
            <Form.Label>Added Time</Form.Label>    
            <Form.Control name="time" type="number" defaultValue={time} min="1" max="90" onChange={e => setTime(e.target.value)}></Form.Control>
        </Form.Group>       
        <hr />
          <Button disabled={!submitOk} variant="primary" type="submit">
            Bump
          </Button>
        </Form>
    </Modal.Body>
    </Modal>
  );
}

export default EDCTBumpModal;