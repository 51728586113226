 // eslint-disable-next-line no-unused-vars
 import { useEffect, useState } from 'react';
 // eslint-disable-next-line no-unused-vars
import { Container, Button, ButtonGroup, Modal, Form, Row, Col, Table } from 'react-bootstrap';
import AuthService from '../services/AuthService';







const Profile = () => {

    const [permissions, setPermissions] = useState([]);
    const [roles, setRoles] = useState([]);
    const [facilities, setFacilities] = useState([]);


    async function refreshUser() {
        await AuthService.refreshUser();
            setPermissions(AuthService.permissions());
            setRoles(AuthService.roles());
            setFacilities(AuthService.facilities());
    }


    useEffect(() => {        
        refreshUser();
    },[])


    return (
        <Container className="profile-body">
            <h5 className="edct-header page-header"> { AuthService.userFullName() } ({AuthService.cid()})</h5>


            { facilities.length>0 && (
                <Container className="mt-5">
                <h6>Facilities</h6>
                <Table responsive striped variant="dark">
                    <tbody>
                    {
                        facilities.map((facility,idx) => {
                        return (
                            <tr key={idx}><td>{facility.name}</td></tr>
                            )
                        })}
                    </tbody>
                </Table>
            </Container>



            )}


            { roles.length>0 && (
                <Container className="mt-5">
                    <h6>Roles</h6>
                    <Table responsive striped variant="dark">
                        <tbody>
                        {
                            roles.map((role,idx) => {
                            return (
                                <tr key={idx}><td>{role.description}</td></tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </Container>
            ) }


            { permissions.length>0 && (
                <Container className="mt-5">
                    <h6>Permissions</h6>
                    <Table responsive striped variant="dark">
                        <tbody>
                        {
                            permissions.map((permission,idx) => {
                            return (
                                <tr key={idx} ><td>{permission.description}</td></tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </Container>
            ) }


            



        </Container>
        

    )




};

export default Profile;


