// eslint-disable-next-line no-unused-vars
import { useEffect, useState } from 'react';
import { Container, Button, ButtonGroup, Modal, Form, Row, Col } from 'react-bootstrap';
import moment from 'moment';
import APIService from '../services/APIService';

const TMUAircraftModal = (props) => { 
    const [callsign, setCallsign] = useState("");
    const [scheduledTime, setScheduledTime] = useState("");
    const [displayedTime, setDisplayedTime] = useState("");
    const [frozen, setFrozen] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        var dbTime = encodeURIComponent(moment.utc(scheduledTime).format("YYYY-MM-DD HH:mm:ss"));
        
        // eslint-disable-next-line no-unused-vars
        var res = await APIService.apiGET(`${process.env.REACT_APP_API_URL}/api/edct/reschedule?callsign=${callsign}&frozen=${frozen}&newtime=${dbTime}`);
        //console.log(encodeURIComponent(dbTime));

        
        
      };

      const show = () => {
        if (props.parms === undefined) {
          setFrozen(false);
            
        }
        else{
            
            setCallsign(props.parms.callsign);
            setScheduledTime(props.parms.eta);
            setDisplayedTime(moment.utc(props.parms.eta).format('HHmm'));  
            setFrozen(props.parms.status==="F_STA");      
        }

      };

     
      const updateSTime = (t) => {
            var cur = moment.utc(scheduledTime);
            cur.add(t,'minutes');
            setScheduledTime(cur.format("YYYY-MM-DDTHH:mm:ss"));
            setDisplayedTime(cur.format('HHmm'));
      }

     


    return (
        <Modal show={props.show_func} onShow={show} onHide={props.close_func}>
            
          <Modal.Body>
            <Container className="AircraftModalCont" style={{ background: "white", width:"400px"}}>
              <h5 className="text-center">{callsign}</h5>
              <hr/>
              <Form onSubmit={handleSubmit}>

                <Form.Group as={Row} controlId="aircraftcont.EDCTSelect">
                <Form.Label column>Scheduled Arrival</Form.Label>
                <Col>
                    <Form.Control type="text" value={displayedTime} readOnly />
                </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="aircraftcont.EDCTButtons">
                    <Form.Label column></Form.Label>
                    <ButtonGroup as={Col}>
                        <Button variant="info" onClick={() => { updateSTime(-1) }} >-1</Button>
                        <Button variant="info" onClick={() => { updateSTime(1) }} >+1</Button>
                    </ButtonGroup>

                </Form.Group>
                <Form.Group as={Row} controlId="aircraftcont.Frozen">
                  <Form.Label column>Frozen</Form.Label>
                  <Col><Form.Check type="checkbox" checked={!!frozen} onChange={(e) => setFrozen(e.target.checked) } /></Col>
                  
                </Form.Group>
                

                <hr />
                <Container className="text-center">
                <ButtonGroup className="mb-2">

                <Button type="submit" onClick={props.close_func} variant="success">Save and Close</Button>
              </ButtonGroup>
            </Container>  
                
              </Form>
            </Container>
          </Modal.Body>
        </Modal>
      );

}


export default TMUAircraftModal;