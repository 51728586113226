// eslint-disable-next-line no-unused-vars
import { useEffect, useState } from 'react';
 // eslint-disable-next-line no-unused-vars
import { Container, Button, ButtonGroup, Modal, Form, Row, Col } from 'react-bootstrap';
import { Select2 } from "select2-react-component";
import 'select2-component/dist/select2.min.css';

const CreateRestrictionModal = (props) => {

  const [id, setID] = useState("") 
  const [icao, setIcao] = useState("");
  const [minit, setMinit] = useState("");
  const [mergeFixes, setMergeFixes] = useState([]);
  const [fixes, setFixes] = useState([]);
  
  

  const handleSubmit = (e) => {
    e.preventDefault();
    
    props.saveRestriction(id,e.target.icao.value,fixes,e.target.minit.value);
    
  }
  
  const show = () =>{

    if(props.mergeFixes!== undefined) {
      setMergeFixes(props.mergeFixes());
    }
  
      //New Airport
      setID("");
      setIcao("");
      setMinit("");
      
    
    

  }

  return (
    <Modal onShow={show} show={props.show_func} onHide={props.close_func}>
      <Modal.Header closeButton>
        <Modal.Title>Restriction</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>Airport ICAO</Form.Label>
            <Form.Control name="icao" type="text" maxLength="4" value={icao} placeholder="ICAO" onChange={e => setIcao(e.target.value)} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Fix</Form.Label>
            <div className="stream-cfg"><Select2 allowClear="true" value={fixes} data={mergeFixes} placeholder="Fix (or blank)" update={value => setFixes(value)} ></Select2></div>
          </Form.Group> 
          <Form.Group>
            <Form.Label>Delay (minit) </Form.Label>
            <Form.Control name="minit" type="text" maxLength="2" value={minit} placeholder="Delay (Enter 0 for Ground Stop)" onChange={e => setMinit(e.target.value)} />
          </Form.Group>
          
          <Button variant="primary" type="submit">
            Save
          </Button>
        </Form>
        </Modal.Body>
    </Modal>
  );
}

export default CreateRestrictionModal;