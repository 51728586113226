
import { useState } from 'react';
import { Button, Modal, Form, Col, Row } from 'react-bootstrap';
import { Select2 } from "select2-react-component";
import 'select2-component/dist/select2.min.css';

const CreateStreamModal = (props) => {

  const [departure, setDeparture] = useState("");
  const [arrival, setArrival] = useState("");
  const [timeMins, setTimeMins] = useState(0);
  const [timeSecs, setTimeSecs] = useState(0);
  const [fixes, setFixes] = useState([]);
  const [mergeFixes, setMergeFixes] = useState([]);

 
  const handleSubmit = (e) => {
    e.preventDefault();
    let time = String(timeMins)+":"+String(timeSecs);
    props.createStream(departure,arrival,time,fixes);
    
  }
  

  const show = () =>{
    if(props.mergeFixes!== undefined) {
      setMergeFixes(props.mergeFixes());
    }
    
  }

  return (
    <Modal onShow={show} show={props.show_func} onHide={props.close_func}>
      <Modal.Header closeButton>
        <Modal.Title>EDCT Configuration</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>Departure</Form.Label>
            <Form.Control name="departure" type="text" maxLength="4" value={departure} placeholder="ICAO" onChange={e => setDeparture(e.target.value)} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Arrival</Form.Label>
            <Form.Control name="arrival" type="text" value={arrival} maxLength="4" placeholder="ICAO" onChange={e => setArrival(e.target.value)} />
          </Form.Group> 
          <Form.Group>   
          <Form.Label>Time</Form.Label>       
            <Row>
            <Col><Form.Control type="number" placeholder="0" defaultValue={timeMins} onChange={e => setTimeMins(e.target.value)}/></Col>
            
            :

            <Col><Form.Control className="ms-0 me-auto" type="number" placeholder="0" defaultValue={timeSecs} min="0" max="59" onChange={e => setTimeSecs(e.target.value)}/></Col>
            </Row>
            
          
          </Form.Group>
          <Form.Group>
            <Form.Label>Streams</Form.Label>
            <div className="stream-cfg"><Select2 multiple="true" value={fixes} data={mergeFixes} update={value => setFixes(value)} ></Select2></div>
          </Form.Group> 
          
          <Button variant="primary" type="submit">
            Add
          </Button>
        </Form>
        </Modal.Body>
    </Modal>
  );
}

export default CreateStreamModal;