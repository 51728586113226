import { Container, Row, Col, Table ,ButtonGroup, Button, Form} from 'react-bootstrap'
import { Select2 } from "select2-react-component";
import 'select2-component/dist/select2.min.css';
import { useEffect, useState } from 'react';
import AuthService from '../services/AuthService';
import APIService from '../services/APIService';
import CreateLadderModal from './CreateLadderModal';

const ConfigLadders = (props) => {
  const [ladders, setLadders] = useState([]);
  const [allMergeFixes, setAllMergeFixes] = useState([]);
  const [ladderMergeFixes, setLadderMergeFixes] = useState([]);

  const [editMode, setEditMode] = useState([]);
  const [airport, setAirport] = useState("");
  const [visible, setVisible] = useState(true);
  const [name, setName] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [airports, setAirports] = useState([]);
  const [facilities, setFacilities] = useState([]);

  const [activeFixes, setActiveFixes] = useState([]);

  const getLadders = async () => {
    try {
      const res = await APIService.apiGET(`${process.env.REACT_APP_API_URL}/api/config/ladders`);
      let init_edit_mode = [];
      let init_merge = [];
      let out = [];

      res.data.forEach((item) => {
        if(AuthService.hasPermission("facility."+item["airport"]["facility_id"]+".admin") || AuthService.hasPermission("admin.ladders")) {
          
          out.push(item);
          init_edit_mode.push(false)
          init_merge.push(item.fixes.map((fix)=>{ return fix.id}));
        }
      });
      
      setEditMode(init_edit_mode);
      setLadderMergeFixes(init_merge);
      setLadders(out);

    } catch (err) {

      console.log(err);
    }
    
  };

  const getAirports = async () => {
    try { 
      const res = await APIService.apiGET(`${process.env.REACT_APP_API_URL}/api/config/airports`);   
      let out = [];
      res.data.forEach((i) => {
        if(AuthService.hasPermission("facility."+i["facility_id"]+".admin") || AuthService.hasPermission("admin.stars")) {
            
          out.push(i);
        }
      })
      setAirports(out);
    }
    catch (err) {
      console.log(err.response.status);
      if(err.response.status===401) {
        console.log("refreshing?");
        await AuthService.refreshTokens();  
      }
      else {
        console.log(err);
      }          
    }     
  }

  const enableEditMode = (idx) => {
    
    setAirport(ladders[idx].airport.icao);
    setName(ladders[idx].name);
    setVisible(ladders[idx].visible)
    
    
    setActiveFixes(ladders[idx].fixes.map((fix)=>{ return fix.id}))
    
    editMode[idx] = true;
    setEditMode([...editMode]);
  };

  const sortMerge = (a,b) => {
    return  (a.label > b.label) ? 1 : -1
  }

  const getMergeFixes = async () => {
    try {
      const res = await APIService.apiGET(`${process.env.REACT_APP_API_URL}/api/config/stars`);
      let stars = res.data;
      let fixes = new Set();
      let fixIDs = new Set();

      // eslint-disable-next-line array-callback-return
      stars.map((item,idx) => {     
        if(AuthService.hasPermission("facility."+item["airport"]["facility_id"]+".admin") || AuthService.hasPermission("admin.ladders")) {
          if(!fixIDs.has(item.fix.id)) {
            fixIDs.add(item.fix.id);      
            fixes.add( { value: item.fix.id, label: item.fix.name} );
          }  
        }
               
      });
      
      setAllMergeFixes(Array.from(fixes).sort(sortMerge));

    }
    catch (err) {
      console.log("Merge Fix error?");
      console.log(err);
    }
    
  };

  const getFacilities = async () => {
    try { 
      const res = await APIService.apiGET(`${process.env.REACT_APP_API_URL}/api/config/facilities`);   
      let out = [];
      res.data.forEach((f) => {
        if(AuthService.hasPermission("facility."+f["id"]+".admin") || AuthService.hasPermission("admin.airports")) {
            
          out.push(f);
        }
      })
      setFacilities(out);
    }
    catch (err) {
      console.log(err.response.status);
      if(err.response.status===401) {
        console.log("refreshing?");
        await AuthService.refreshTokens();  
      }
      else {
        console.log(err);
      }          
    }     
  }

  const saveEdits = async (idx) => {

    // eslint-disable-next-line no-unused-vars
    try {
      //eslint-disable-next-line no-unused-vars
      const res = await APIService.apiPOST(`${process.env.REACT_APP_API_URL}/api/config/ladders/update`, {
        "id": ladders[idx]["id"],
        "airport": airport,
        "name": name,
        "visible": visible,
        "fixes": activeFixes
      });
  
      disableEditMode(idx);
      getLadders();


    }
    catch (err) {
      console.log(err.response);
    }
    
  }

  const createLadder = async (airport, name, visible, fixes) => {    

    const res = await APIService.apiPOST(`${process.env.REACT_APP_API_URL}/api/config/ladders/update`, {      
      "airport": airport,
      "name": name,
      "visible": visible,
      "fixes": fixes
    });

    console.log(res);

    if(res.status===204) {
      close_modal();
      getLadders();

    }

    
  }


  const popupMergeFixes = () => {

    return allMergeFixes;

  }

  const disableEditMode = (idx) => {
    editMode[idx] = false;
    setEditMode([...editMode]);
  }

  const deleteLadder = async (idx) => {
    
    const r = window.confirm("Do you really want to delete "+ladders[idx].name+"?"); 
        if(r === true) {
          //eslint-disable-next-line no-unused-vars
          const res = await APIService.apiPOST(`${process.env.REACT_APP_API_URL}/api/config/ladders/delete`, {
            "id": ladders[idx].id
          });
      
          disableEditMode(idx);
          getLadders();
          
        }    
  }

  const create_modal = () => {
    
    setShowModal(true);
}

const close_modal = () => {
    setShowModal(false)
} 


  const onLoad = async () => {
    
    
    await getMergeFixes();
    await getLadders();
    await getAirports();
    await getFacilities();
  
  
  }
  
    useEffect(() => {
      onLoad();
      
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])





  return (
    <Container className="mono">

        <Row>
          <Col>
            <h2 style={{color: "white", marginTop:"10px"}}>Ladder Configuration</h2>
          </Col>
          <Col style={{textAlign:"right"}}>{ ( facilities.length>0 || AuthService.hasPermission("admin.ladders")) && (
              <Button variant="primary" style={{marginTop: "10px"}} onClick={create_modal}>Add Ladder</Button>
          )}            
          </Col>
        </Row>


      
      <Row>
        <Col>
          <Table striped bordered hover variant="dark">
            <thead>
              <tr>
                <th>Airport</th>
                <th>Name</th>
                <th>Merge Fixes</th>
                <th>Visible</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>

            { ladders.map((ladder, idx) => {
                if (editMode[idx]) {
                  return (
                    <tr key={idx}>
                      <td>
                      <Form.Control as="select" name="airport" value={airport} onChange={e => setAirport(e.target.value)}>
                        <option key="ZZZ" value="ZZZ">---Select One---</option>
                        {airports.map((f, idx) => {
                          return (<option key={idx} value={f.icao} >{f.icao}</option>)
                        })}
                      </Form.Control>
                      </td>
                      <td><Form.Control type="text" placeholder="Name" defaultValue={name} onChange={e => setName(e.target.value)}/></td>
                      
                      <td><div className="stream-cfg"><Select2 multiple="true" value={activeFixes} data={allMergeFixes} update={value => setActiveFixes(value)} ></Select2></div></td>
                      <td><Form.Check type="checkbox" checked={visible} onChange={e => setVisible(e.target.checked)}/></td>
                      <td>
                      <ButtonGroup aria-label="save group">
                        <Button variant="success" size="sm" onClick={() => saveEdits(idx)}>Save</Button>{' '}
                        <Button variant="warning" size="sm" onClick={() => disableEditMode(idx)}>Cancel</Button>
                      </ButtonGroup>
                      </td>
                    </tr>
                  )
                } else {
                  return (

                  <tr key={idx}>
                    <td>{ladder["airport"]["icao"]}</td>
                    <td>{ladder["name"]}</td>
                    
                    <td ><div className="stream-cfg"><Select2 disabled="true" multiple="true" value={ladderMergeFixes[idx]} data={allMergeFixes} ></Select2></div></td>
                    <td> { ladder["visible"]===1 && ( <div>✅</div>   )  }   {ladder["visible"]===0 && ( <div>❌</div>) }</td>
                    <td><ButtonGroup aria-label="edit group">
                          <Button variant="warning" size="sm" onClick={() => enableEditMode(idx)}>Edit</Button> 
                          <Button variant="danger" size="sm" onClick={() => deleteLadder(idx)}>Delete</Button>
                        </ButtonGroup></td>
                  </tr>
                )
                }})}


              
            </tbody>
          </Table>
        </Col>
      </Row>
      <CreateLadderModal show_func={showModal} close_func={close_modal} airports={airports} mergeFixes={popupMergeFixes} createLadder={(a, n, v, f) => createLadder(a, n, v, f)}/>
    </Container>
  )
}

export default ConfigLadders;