// eslint-disable-next-line no-unused-vars
import { useEffect, useState } from 'react';
 // eslint-disable-next-line no-unused-vars
import { Container, Button, ButtonGroup, Modal, Form, Row, Col } from 'react-bootstrap';

const CreateFacilityModal = (props) => {

  const handleSubmit = (e) => {
    e.preventDefault();
    props.createFacility(e.target.facility_ident.value, e.target.name.value);
  }

  return (
    <Modal show={props.show_func} onHide={props.close_func}>
      <Modal.Header closeButton>
        <Modal.Title>Add Facility</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>Facility Identifier</Form.Label>
            <Form.Control name="facility_ident" type="text" maxLength="3" placeholder="ZLA" />
          </Form.Group>
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control name="name" type="text" placeholder="Los Angeles ARTCC" />
          </Form.Group>          
          <Button variant="primary" type="submit">
            Save
          </Button>
        </Form>
        </Modal.Body>
    </Modal>
  );
}

export default CreateFacilityModal;