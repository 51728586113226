import { Container } from 'react-bootstrap'
const HomePage = () => {
  return (
    <Container>
      <div style={{ display: "flex", alignItems: "center", minHeight: "100vh", position: "fixed" }}>
        <div>
          <h1 style={{ color: "white" }} >Welcome to CalFlow</h1>
        </div>
      </div>
    </Container>
  )
}

export default HomePage;