// eslint-disable-next-line no-unused-vars
import { useEffect, useState } from 'react';
 // eslint-disable-next-line no-unused-vars
import { Container, Button, ButtonGroup, Modal, Form, Row, Col } from 'react-bootstrap';
import APIService from '../services/APIService';

const CreateAirportModal = (props) => {

  const [id, setID] = useState("") 
  const [icao, setIcao] = useState("");
  const [name, setName] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [facilities, setFacilities] = useState([]);
  const [facilityID, setFacilityID] = useState("ZZZ");


  const handleSubmit = (e) => {
    e.preventDefault();
    
    props.saveAirport(id,e.target.icao.value,e.target.name.value,e.target.latitude.value,e.target.longitude.value,e.target.facility.value);
    
  }
  
  const setAirport = (airport) => {
    setID(airport.id);
    setIcao(airport.icao);
    setName(airport.name);
    setLatitude(airport.latitude);
    setLongitude(airport.longitude);
    setFacilityID(airport.facility_id);   
  }

  const show = () =>{
    if(props.facilities !== undefined) { setFacilities(props.facilities)}
    if(props.airport !== undefined ) { 
      //Existing Airport
      setAirport(props.airport)
    }
    else
    {
      //New Airport
      setID("");
      setIcao("");
      setName("");
      setLatitude("");
      setLongitude("");
      setFacilityID("ZZZ");
    }
    

  }

  const lookupAirport = async (icao) => {
    try {

      const res = await APIService.apiGET("https://api.navdata.org/api/airport/"+icao);
    
      if(res.data.result==="ok") {
        setName(res.data.value.name);
        setLatitude(res.data.value.latitude);
        setLongitude(res.data.value.longitude);
      }

    }
    catch (err) {
      console.log(err);
    }
    

    

  }

  return (
    <Modal onShow={show} show={props.show_func} onHide={props.close_func}>
      <Modal.Header closeButton>
        <Modal.Title>Airport</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>Airport ICAO</Form.Label>
            <Form.Control name="icao" type="text" maxLength="4" value={icao} placeholder="ICAO" onChange={e => setIcao(e.target.value)} />
            <Button onClick={() => lookupAirport(icao)}  variant="info"size="sm" disabled={icao.length<3}>Lookup</Button>
          </Form.Group>
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control name="name" type="text" value={name} placeholder="Airport Name" onChange={e => setName(e.target.value)}/>
          </Form.Group> 
          <Form.Group>
            <Form.Label>Latitude</Form.Label>
            <Form.Control name="latitude" type="text" maxLength="9" value={latitude} placeholder="00.00000" onChange={e => setLatitude(e.target.value)} />
          </Form.Group>  
          <Form.Group>
            <Form.Label>Longitude</Form.Label>
            <Form.Control name="longitude" type="text" maxLength="10" value={longitude} placeholder="-000.00000" onChange={e => setLongitude(e.target.value)} />
          </Form.Group> 
          <Form.Group>
            <Form.Label>Facility</Form.Label>
            <Form.Control as="select" name="facility" value={facilityID} onChange={e => setFacilityID(e.target.value)}>
              <option key="ZZZ" value="ZZZ">---Select One---</option>
              {facilities.map((f, idx) => {
                return (<option key={idx} value={f.id} >{f.name}</option>)
              })}
            </Form.Control>
          </Form.Group> 
          <Button variant="primary" type="submit">
            Save
          </Button>
        </Form>
        </Modal.Body>
    </Modal>
  );
}

export default CreateAirportModal;