 // eslint-disable-next-line no-unused-vars
 import { useEffect, useState } from 'react';
 // eslint-disable-next-line no-unused-vars
import { Container, Button, ButtonGroup, Modal, Form, Row, Col, Table } from 'react-bootstrap';
//import AuthService from '../services/AuthService';







const Status = () => {

   

    useEffect(() => {    
        //OnPageLoad

       
    },[])


    return (
        <Container className="status-body">
            <h5 className="edct-header page-header"> System Status</h5>

            <Container className="mt-5">
                <h6>Under Construction</h6>
            </Container>


            

            



        </Container>
        

    )




};

export default Status;


