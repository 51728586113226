import { Container, Button,  Modal, Form, Row, Col } from 'react-bootstrap';
import {  useState } from 'react';


const LoginModal = (props) => {
    const [cid, setCID] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
        if(props.loginFunc) {
            
            props.loginFunc(cid);
            props.close_func();
        }
      }

    return (

        <Modal show={props.show_func} onHide={props.close_func}>
            <Modal.Body>
                <Container className="LoginControlsCont" style={{ background: "white", width:"300px"}}>
                <h5 className="text-center">Login</h5>
                <hr/>
                <Form onSubmit={handleSubmit}>
                    <Form.Group as={Row} controlId="tlcont.TimeFormatSelect">
                        <Form.Label column>CID</Form.Label>
                        <Col>
                            <Form.Control as="input" maxLength="7" onChange={e => setCID(e.target.value)}>
                           
                            </Form.Control>
                        </Col>
                    </Form.Group>

                    <Container className="text-center">
                        
                            <Button type="submit" className="m-2" size="sm" variant="success">Login with VATSIM</Button>
                            <Button onClick={props.close_func} size="sm" variant="danger">Cancel</Button>
                        
                    </Container>  

                </Form>


                </Container>
            </Modal.Body>
        </Modal>


    )




}

export default LoginModal;
