import { Container, Row, Col, Table ,ButtonGroup, Button, Form} from 'react-bootstrap'
import { Select2 } from "select2-react-component";
import 'select2-component/dist/select2.min.css';
import { useEffect, useState } from 'react';
import AuthService from '../services/AuthService';
import APIService from '../services/APIService';
import CreateRestrictionModal from './CreateRestrictionModal';

const ConfigRestrictions = (props) => {
    const [restrictions, setRestrictions] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [editMode, setEditMode] = useState([]);
    const [airport, setAirport] = useState("");
    const [fix, setFix] = useState("");
    const [minit, setMinit] = useState("");
    const [allMergeFixes, setAllMergeFixes] = useState([]);
    //const [fixes, setFixes] = useState([]);

    const sortMerge = (a,b) => {
      return  (a.label > b.label) ? 1 : -1
    }
  
    const getMergeFixes = async () => {
      try {
        const res = await APIService.apiGET(`${process.env.REACT_APP_API_URL}/api/config/stars`);
        let stars = res.data;
        let fixes = new Set();
        let fixIDs = new Set();
  
        // eslint-disable-next-line array-callback-return
        stars.map((item,idx) => {      
          if(!fixIDs.has(item.fix.id)) {
            fixIDs.add(item.fix.id);      
            fixes.add( { value: item.fix.id, label: item.fix.name} );
          }         
        });

        fixes.add({value:"", label:"" });
        setAllMergeFixes(Array.from(fixes).sort(sortMerge));
  
      }
      catch (err) {
        console.log("Merge Fix error?");
        console.log(err);
      }
      
    };


    const getRestrictions = async () => {
        try {
          const res = await APIService.apiGET(`${process.env.REACT_APP_API_URL}/api/config/restrictions`);
          
          let init_edit_mode = [];
          let init_merge = [];

          res.data.forEach((item) => {
            init_edit_mode.push(false)
            init_merge.push(item.fix_id);
            
          });
          setEditMode(init_edit_mode);
          //setFixes(init_merge);
          setRestrictions(res.data);
    
        } catch (err) {
    
          console.log(err);
        }
        
      };

      const saveEdits = async (idx) => {

        // eslint-disable-next-line no-unused-vars
        try {
          // eslint-disable-next-line no-unused-vars
          const res = await APIService.apiPOST(`${process.env.REACT_APP_API_URL}/api/config/restrictions/update`, {
            "id": restrictions[idx]["id"],
            "icao": airport,
            "fix": fix,
            "minit": minit
          });
      
          disableEditMode(idx);
          getRestrictions();
    
    
        }
        catch (err) {
          console.log(err.response);
        }
        
      }

      const popupMergeFixes = () => {

        return allMergeFixes;
    
      }

      const deleteRestriction = async (idx) => {
    
        
              //eslint-disable-next-line no-unused-vars
              const res = await APIService.apiPOST(`${process.env.REACT_APP_API_URL}/api/config/restrictions/delete`, {
                "id": restrictions[idx].id
              });
          
              disableEditMode(idx);
              getRestrictions();
              
               
      }

      const enableEditMode = (idx) => {
    
        setAirport(restrictions[idx].arrival.icao);
        setFix(restrictions[idx].fix);
        
        setMinit(restrictions[idx].minit);

        editMode[idx] = true;
        setEditMode([...editMode]);
      };

    const show_modal = () => {
        setShowModal(true);
      }
    
      const close_modal = () => {
        setShowModal(false)
      }  

      const disableEditMode = (idx) => {
        editMode[idx] = false;
        setEditMode([...editMode]);
      }


      const saveRestriction = async (id, icao, fix, minit) => {
        try { 
            console.log(id,icao);
          // eslint-disable-next-line no-unused-vars
          const res = await APIService.apiPOST(`${process.env.REACT_APP_API_URL}/api/config/restrictions/update`, {
            "id": id,
            "icao": icao,
            "fix": fix,
            "minit": minit,
        
            
          });
          getRestrictions();
        }
        catch (err) {
          console.log(err);
        }
    
        close_modal();
      }


      const onLoad = async () => {
        
        await getMergeFixes();
        await getRestrictions();
      
      
      }
      
        useEffect(() => {
          onLoad();
          
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

    return (
        <Container className="mono">
          <Row>
            <Col>
              <h2 style={{color: "white", marginTop:"10px"}}>Restrictions</h2>
            </Col>
            <Col style={{textAlign:"right"}}>
              <Button variant="primary" style={{marginTop: "10px"}} onClick={show_modal}>Add Restriction</Button>{' '}
            </Col>
          </Row>
          <Row>
            <Col>
              <Table striped bordered hover variant="dark">
                <thead>
                  <tr>
                    <th>Airport</th>
                    <th>Fix</th>                 
                    <th>Delay (minit)</th>
                  </tr>
                </thead>
                <tbody>

                { restrictions.map((restriction, idx) => {
                if (editMode[idx]) {
                  return (
                    <tr key={idx}>
                      <td><Form.Control type="text" placeholder="ICAO" defaultValue={airport} onChange={e => setAirport(e.target.value)} /></td>
                      <td><div className="stream-cfg"><Select2 allowClear="true" value={fix} data={allMergeFixes} placeholder="Fix (or blank)" update={value => setFix(value)} ></Select2></div></td>
                      <td><Form.Control type="text" placeholder="Delay" defaultValue={minit} onChange={e => setMinit(e.target.value)} /></td>
                      
                 
                      <td>
                      <ButtonGroup aria-label="save group">
                        <Button variant="success" size="sm" onClick={() => saveEdits(idx)}>Save</Button>{' '}
                        <Button variant="warning" size="sm" onClick={() => disableEditMode(idx)}>Cancel</Button>
                      </ButtonGroup>
                      </td>
                    </tr>
                  )
                } else {
                  return (

                  <tr key={idx}>
                    <td>{restriction["arrival"]["icao"]}</td>
                    <td ><div className="stream-cfg"><Select2 disabled="true" value={restriction.fix_id} data={allMergeFixes} ></Select2></div></td>
                    <td>
                      
                      
                      {
                        restriction["minit"]===0 && ( <Button variant="danger" >GROUND STOP</Button> ) }{ 
                        restriction["minit"]!==0 && ( restriction["minit"] ) }
                        </td>

                    
                    
                    <td><ButtonGroup aria-label="edit group">
                          <Button variant="warning" size="sm" onClick={() => enableEditMode(idx)}>Edit</Button> 
                          <Button variant="danger" size="sm" onClick={() => deleteRestriction(idx)}>Delete</Button>
                        </ButtonGroup></td>
                  </tr>
                )
                }})}

                  
                </tbody>
              </Table>
            </Col>
          </Row>
          <CreateRestrictionModal show_func={showModal} close_func={close_modal} mergeFixes={popupMergeFixes} saveRestriction={(id, icao, fix, minit) => saveRestriction(id, icao, fix, minit)} />
          
        </Container>
      )
    
    



};

export default ConfigRestrictions;

