import EDCTLadder from './EDCTLadder';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import APIService from '../services/APIService';

const EDCTLadderWrapper = (props) => {
  let { icao } = useParams();
  const [airports, setAirports] = useState([]);

  useEffect(() => {
    const getAirports = async (icao) => {
      try {
        const { data } = await APIService.apiGET(`${process.env.REACT_APP_API_URL}/api/edcts?icao=${icao}`);
        setAirports(data);
      }
      catch (err) {
        if(err.response !== undefined) {
          if(err.response.status===404) {
            window.location.href="/404";
          }
        }
      }
    } 

    getAirports(icao);
    setInterval(() => getAirports(icao), 60000);
  }, [icao])

  return (
    <Container className="tmu-container" style={{maxWidth: "90vw"}}>
      {Object.keys(airports).map((airport, idx) => {
        return <EDCTLadder key={idx} arr_airport={airport} dep_airport={icao.toUpperCase()} airport_name={airport} edcts={airports[airport]}/>
      })}
    </Container>
  );
};

export default EDCTLadderWrapper;