import { Container, Table, Row, Col, Button, ButtonGroup} from 'react-bootstrap';
import { useEffect, useState } from 'react';
import CreateAirportModal from './CreateAirportModal';
import AuthService from '../services/AuthService';
import APIService from '../services/APIService';

const ConfigAirports = (props) => {
    const [airports, setAirports] = useState([]);
    const [facilities, setFacilities] = useState([]);
    
    const [showModal, setShowModal] = useState(false);
 
    
    const [airport, setAirport] = useState({});
    

  
    const enableEditMode = (idx) => {

        setAirport(airports[idx]);
        setShowModal(true);
            
      
  
      
    };

    
    
    const saveAirport = async (id, icao, name, lat, lon, facility) => {
      try { 
          console.log(id,icao,name,lat,lon,facility);
        // eslint-disable-next-line no-unused-vars
        const res = await APIService.apiPOST(`${process.env.REACT_APP_API_URL}/api/config/airports/update`, {
          "id": id,
          "icao": icao,
          "name": name,
          "latitude": lat,
          "longitude": lon,
          "facility_id": facility
          
        });
        getAirports();
      }
      catch (err) {
        console.log(err);
      }

      close_modal();
    }
  
    const deleteAirport = async (idx) => {
        const r = window.confirm("Do you really want to Delete "+airports[idx].name+"?"); 
        if(r === true)
        { 
              try {
                // eslint-disable-next-line no-unused-vars
                const res = await APIService.apiPOST(`${process.env.REACT_APP_API_URL}/api/config/airports/delete`, {
                  "id": airports[idx]["id"]
                });         
                
                await getAirports();
              }
              catch (err) {
                console.log(err);
              }
        }
    }
  
    const getAirports = async () => {
        try { 
            const res = await APIService.apiGET(`${process.env.REACT_APP_API_URL}/api/config/airports`);  
            let out = [];
            res.data.forEach((a) => {
              if(AuthService.hasPermission("facility."+a["facility_id"]+".admin") || AuthService.hasPermission("admin.airports")) {
                
                out.push(a);
              }
            })
            
            
            setAirports(out);   
        }
        catch (err) {
            console.log(err);  
            if(err.response.status===401) {
                await AuthService.refreshTokens();
            }
            else {
            console.log(err);
            }
      }     
    }

    const getFacilities = async () => {
        try { 
          const res = await APIService.apiGET(`${process.env.REACT_APP_API_URL}/api/config/facilities`);   
          let out = [];
          res.data.forEach((f) => {
            if(AuthService.hasPermission("facility."+f["id"]+".admin") || AuthService.hasPermission("admin.airports")) {
                
              out.push(f);
            }
          })
          setFacilities(out);
        }
        catch (err) {
          console.log(err.response.status);
          if(err.response.status===401) {
            console.log("refreshing?");
            await AuthService.refreshTokens();  
          }
          else {
            console.log(err);
          }          
        }     
      }

  
    const create_modal = () => {
        setAirport(undefined);
        setShowModal(true);
    }
  
    const close_modal = () => {
        setShowModal(false)
    }  
  
    useEffect(() => {
        
        getFacilities();
        getAirports();
    }, [])
  
    return (
      <Container className="mono">
        <Row>
          <Col>
            <h2 style={{color: "white", marginTop:"10px"}}>Airports</h2>
          </Col>
          <Col style={{textAlign:"right"}}>{ (facilities.length>0) && (
              <Button variant="primary" style={{marginTop: "10px"}} onClick={create_modal}>Add Airport</Button>
          )}            
          </Col>
        </Row>
        <Row>
          <Col>
            <Table striped bordered hover variant="dark">
              <thead>
                <tr>
                  <th>ICAO</th>
                  <th>Name</th>                 
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {airports.map((airport, idx) => {
                    return (
                      <tr key={idx}>
                        <td>{airport["icao"]}</td>
                        <td>{airport["name"]}</td>
                        
                        <td> 

                            <ButtonGroup aria-label="edit group">
                              {
                                AuthService.hasPermission("facility."+airport["facility_id"]+".admin") ? ( <div>
                                <Button className="me-1" variant="primary" size="sm" href={ "/airportAdmin/"+airport.icao }>Manage</Button>
                                <Button className="me-1" variant="warning" size="sm" onClick={() => enableEditMode(idx)}>Edit</Button> 
                                <Button variant="danger" size="sm" onClick={() => deleteAirport(idx)}>Delete</Button>
                                
                                </div>

                              ) : ( 

                                <></>
                              )  }
                              
                               
                              
                            </ButtonGroup>
                                                 
                        </td>
                      </tr>
                    )
                  }
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
        <CreateAirportModal show_func={showModal} close_func={close_modal} saveAirport={(id, icao, name, lat, lon, facility) => saveAirport(id, icao, name, lat, lon, facility)} airport={airport} facilities={facilities}/>
      </Container>
    )
  
  };
  
  export default ConfigAirports;
  