// eslint-disable-next-line no-unused-vars
import { Menu, Item, Separator, Submenu, theme} from "react-contexify";
import 'react-contexify/dist/ReactContexify.css';

const TMUAircraftContextMenu = ({menuID, handleItemClick}) => {  
        
    return ( 
        <Menu theme={theme.dark} id={menuID}>
            <Item onClick={handleItemClick}>Schedule</Item>
            <Item disabled onClick={handleItemClick}>Freeze</Item> 
        </Menu>
    )
  }

export default TMUAircraftContextMenu;