 // eslint-disable-next-line no-unused-vars
 import { useEffect, useState} from 'react';
 import { useParams } from 'react-router-dom';
 // eslint-disable-next-line no-unused-vars
import { Container, Button, ButtonGroup, Modal, Form, Row, Col, Table, Accordion } from 'react-bootstrap';
import AuthService from '../services/AuthService';
import APIService from '../services/APIService';






const AirportAdmin = (props) => {

    let { airportID } = useParams();

    
    const [airport, setAirport] = useState({});
    const [restrictions, setRestrictions] = useState([]);
    


    const verifyAuth = async (f) => {

        console.log(f);

        console.log("facility."+f+".admin");
        console.log(AuthService.permissions());

        console.log(AuthService.hasPermission("facility."+f+".admin"));
        if(!(AuthService.hasPermission("facility."+f+".admin"))) {
            window.location="/";
            
        }


    }

    const getAirports = async () => {
        try { 
            const res = await APIService.apiGET(`${process.env.REACT_APP_API_URL}/api/config/airports`);

            // eslint-disable-next-line no-unused-vars
            const junk = res.data.map((f, idx) => {
                
                if(f["icao"]===airportID) {
                    setAirport(f);
                    verifyAuth(f["facility_id"]);
                    
                    

                }
                return ""
            });
      
        }
        catch (err) {
          console.log(err.response.status);
          if(err.response.status===401) {
            console.log("refreshing?");
            await AuthService.refreshTokens();
  
          }
          else {
            console.log(err);
          }
          
        }     
    }

    const getRestrictions = async () => {
        try {
          const res = await APIService.apiGET(`${process.env.REACT_APP_API_URL}/api/config/restrictions`);
          let result = [];

          res.data.forEach((item) => {
            console.log(item);
            if(item.arrival.icao===airportID) {
                result.push(item);

            }
            
          });
          
          
          setRestrictions(result);
    
        } catch (err) {
    
          console.log(err);
        }
        
    };

    
    
    


    useEffect(() => {    
        
        

        getAirports();
        getRestrictions();
        
        
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])


    return (
        <Container className="mono">
            <h1 className="edct-header page-header"> {airport["name"]} ({airport["icao"]})</h1>

            <Row className="mt-5">
                <Col >


                <h5 className="ms-2">Restrictions</h5>

                </Col>
                <Col style={{textAlign:"right"}}>Button</Col>
                
                </Row>
                <Row>
                <Col>

                <Table striped bordered hover variant="dark">
                <thead>
                    <tr>
                    <th>Fix</th>
                    <th>Delay (minit)</th>                 
                    <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        restrictions.map((r,idx) => {
                            return (
                                <tr key={idx}><td>{r.fix.name} </td><td> {r.minit}</td><td></td></tr>
                                )
                            })
                    }


                    </tbody>

                    </Table>


                
                </Col>
                
            </Row>


            
            


            <Container className="mt-5">
                 
                

            </Container>

            


            



        </Container>
        

    )




};

export default AirportAdmin;


