import { useEffect, useRef, useState, useCallback } from 'react';
import TMUModal from './TMUModal';
import TMUAircraftModal from './TMUAircraftModal';
import moment from 'moment';
import { useContextMenu } from "react-contexify";
import TMUAircraftContextMenu from './TMUAircraftContextMenu';
import AuthService from '../services/AuthService';
import APIService from '../services/APIService';
import useWindowSize from './UseWindowSize';



// TODO: will fetch freeze horizon from server
// TODO: restructure server data so STA and ETA for each callsign are included in each line
const FREEZE_HORIZON_MINS = 20;

var colors = {
  "ETA": "#00ff00",
  "UF_STA": "#e6e600", // Unfrozen
  "F_STA": "#7cb3f2",  // Frozen
  "OS_STA": "#4ddbff", // Open slot
  "EDCT": "#00ff00",
  "PROP": "#f28816"  //Pending Departure
};

var delay_color = {
  "GREEN": "#00ff00",
  "YELLOW": "#e6e600",
  "ORANGE": "#f28816",
  "RED": "#ff0000",
  "GRAY": "#8a8a8a"
};

// TODO finish checking settings for airplanes on the ladder

const TMULadder = (props) => {
  const canvasRef = useRef(null);
  const [flightState, setFlightState] = useState([])
  const [showModal, setShowModal] = useState(false);
  const [showAircraftModal, setShowAircraftModal] = useState(false);
  const [ladderSettings, setLadderSettings] = useState({});
  const [aircraftParms, setAircraftParms] = useState(undefined);
  const SCALE_FACTOR = 1.25;
  const OVERLAP_FRAC = 0.02;
  const windowSize = useWindowSize();


  const updateSettings = (settings) => {
    setLadderSettings(settings)
    props.updateCallback(settings, props.fix)
  }

  const PIXEL_RATIO = (ctx) => {
    const dpr = window.devicePixelRatio || 1;
    const bsr = (ctx.webkitBackingStorePixelRatio ||
      ctx.mozBackingStorePixelRatio ||
      ctx.msBackingStorePixelRatio ||
      ctx.oBackingStorePixelRatio ||
      ctx.backingStorePixelRatio || 1);

    return dpr / bsr;
  };

  // Build ladder structure
  const buildLadder = useCallback((ctx, f, left_ladder_bound, right_ladder_bound, time) => {
    // F is the scale factor for the browser resolution
    // Time is the preference-set length for the ladder
    let init_offset = moment.utc().add(time / 60, 'minutes');

    // Line parameters
    ctx.strokeStyle = "white";
    ctx.lineWidth = 1;

    // Draw 
    ctx.beginPath();

    // Draw left
    ctx.moveTo(left_ladder_bound, 0);
    ctx.lineTo(left_ladder_bound, ctx.canvas.height);

    // Draw right
    ctx.moveTo(right_ladder_bound, 0);
    ctx.lineTo(right_ladder_bound, ctx.canvas.height);

    // Draw increments
    let x_space = ctx.canvas.height / (time * f);     // the incremental vertical distance between each tick
    let count = 0;
    let offset = init_offset;
    for (let i = 0; i <= ctx.canvas.height; i += x_space, count++) {
      // Left ticks and text
      ctx.moveTo(left_ladder_bound, i);
      let minutes = offset.minutes()
      let seconds = offset.seconds()
      if (minutes % 5 === 0 && seconds % 60 === 0 && count !== 0 && count !== time) {
        ctx.lineTo(left_ladder_bound - 6, i);

        // Text parameters
        ctx.fillStyle = "white";
        ctx.textBaseline = "middle";

        let time_margin = 4;
        let show_hours = false;
        if (ladderSettings["timeFormat"] === "HHmm") {
          // time_margin = 4;
          show_hours = true;
        }

        if (ladderSettings["fontSize"] === "large" && show_hours) {
          time_margin -= 2;
        } else if (ladderSettings["fontSize"] === "small" && show_hours) {
          time_margin += 4;
        } else if (ladderSettings["fontSize"] === "small" && !show_hours) {
          time_margin += 2;
        } else if (ladderSettings["fontSize"] === "large" && !show_hours) {
          time_margin -= 1;
        }

        
        if (ladderSettings["timeFormat"] === "mm" && minutes === 0 && seconds % 60 === 0) {
          // Draw hour and min stacked for mm format when on an hour boundary
          ctx.textBaseline = "bottom";
          ctx.fillText(offset.format("HH"), left_ladder_bound + time_margin, i)
          ctx.textBaseline = "top";
          ctx.fillText(offset.format("mm"), left_ladder_bound + time_margin, i)
        } else {
          // Fill in time per time format setting
          ctx.fillText(offset.format(ladderSettings["timeFormat"]), left_ladder_bound + time_margin, i)
        }
      } else if (minutes % 5 === 0 && seconds % 60 === 0) {
        ctx.lineTo(left_ladder_bound - 6, i);
      } else if (minutes % 5 !== 0 && seconds % 60 === 0) {
        ctx.lineTo(left_ladder_bound - 4, i);
      }

      // Right ticks
      ctx.moveTo(right_ladder_bound, i);
      if (minutes % 5 === 0 && seconds % 60 === 0) {
        ctx.lineTo(right_ladder_bound + 6, i);
      } else if (minutes % 5 !== 0 && seconds % 60 === 0) {
        ctx.lineTo(right_ladder_bound + 4, i);
      }

      offset = init_offset.add(-1, 'seconds');
    }

    ctx.stroke();
    ctx.closePath();

    ctx.save();

    ctx.strokeStyle = colors["F_STA"];
    

    ctx.beginPath();
    ctx.lineWidth = 5;
    // Freeze horizon y coord

    // Find fraction of ladder
    let fh_fraction_of_ladder = (1 - parseFloat(FREEZE_HORIZON_MINS * 60 / time).toFixed(4));
  
    // Compute y coordinate
    let fh_y = (ctx.canvas.height / f) * fh_fraction_of_ladder;
    
    ctx.moveTo(left_ladder_bound, fh_y);

    ctx.lineTo(left_ladder_bound - 6, fh_y);
    
    ctx.stroke();
    ctx.closePath();
    ctx.restore();

  },[ladderSettings]);


  const drawSymbol = (ctx, symbol, multiplier, color, lb, y_coord) => {
    ctx.lineWidth = 1;
    if (symbol === "B757") {
      ctx.beginPath();
      ctx.strokeStyle = color;      
      ctx.moveTo(lb + (multiplier * 17), y_coord);
      ctx.lineTo(lb + (multiplier * 22), y_coord + 4);
      ctx.lineTo(lb + (multiplier * 22), y_coord - 4);
      ctx.lineTo(lb + (multiplier * 17), y_coord);
      ctx.stroke();
      ctx.closePath();
    } else if (symbol === "heavy") {
      ctx.fillStyle = color;
      ctx.beginPath();
      ctx.moveTo(lb + (multiplier * 17), y_coord);
      ctx.lineTo(lb + (multiplier * 22), y_coord + 4);
      ctx.lineTo(lb + (multiplier * 22), y_coord - 4);
      ctx.fill();
    } else if (symbol === "large") {
      ctx.beginPath();
      ctx.strokeStyle = color;
      
      ctx.moveTo(lb + (multiplier * 17), y_coord);
      ctx.lineTo(lb + (multiplier * 22), y_coord + 4);
      ctx.moveTo(lb + (multiplier * 17), y_coord);
      ctx.lineTo(lb + (multiplier * 22), y_coord - 4);
      ctx.stroke();
      ctx.closePath();
    } else if (symbol === "small_prop") {
      ctx.fillStyle = color;
      
      let value = 16;
      if (multiplier < 0) {
        value = 24;
      }
      ctx.strokeRect(lb + (multiplier * value), y_coord - 3, 8, 6);
    } else if (symbol === "small_jet") {
      ctx.fillStyle = color;
      let value = 17;
      if (multiplier < 0) {
        value = 23;
      }
      ctx.fillRect(lb + (multiplier * value), y_coord - 3, 6, 6);
    } else if (symbol === "super") {
      ctx.fillStyle = color;
      let value = 17;
      if (multiplier < 0) {
        value = 20;
      }
      ctx.fillRect(lb + (multiplier * value), y_coord - 5, 3, 10);
    }

  }

  const drawAirplanes = useCallback((ctx, sf, airplanes, lb, rb, time, overlap) => {
    let exclusion_left = new Set();
    let exclusion_right = new Set();
    airplanes.forEach((item, idx) => {
      let multiplier = 1;
      let border = rb;
      if (item["status"] === "ETA") {
        multiplier = -1;
        border = lb;
      }

      let eta = moment(item["eta"]).utc();
      let current_utc = moment.utc().format("YYYY-MM-DDTHH:mm:ss");

      // Compute Difference
      let diff = eta.diff(current_utc, "seconds");

      // Find fraction of ladder
      let fraction_of_ladder = (1 - parseFloat(diff / time).toFixed(4));

      // Compute y coordinate
      let y_coord = (ctx.canvas.height / sf) * fraction_of_ladder;

      // Don't draw if past end of ladder
      if (y_coord >= (ctx.canvas.height / sf)) {
        return;
      }

      var tag_color = colors[item["status"]];

      // Check exclusion zone
      let exclusion = exclusion_right;
      if (multiplier < 0) {
        exclusion = exclusion_left;
      }

      let x_space = parseFloat(ctx.canvas.height / (time * sf));
      exclusion.forEach((ex, idx) => {
        let ex_diff = parseFloat(ex) - parseFloat(fraction_of_ladder); // the difference in positions between item in the set and our current plane
  
     

        // Default spacing factor for normal font size
        let SPACING_FACTOR = 16 / (time );  // We need to consider the timeline length when determining the spacing factor... for.... reasons?
        if (ladderSettings["fontSize"] === "small") {
            SPACING_FACTOR = 12 / (time );
        }
        else if (ladderSettings["fontSize"] === "large") {
            SPACING_FACTOR = 15 / (time );
        }
        let NUDGE = 0.001
        while (Math.abs(ex_diff) < (SPACING_FACTOR * (1 / x_space))) {     
          fraction_of_ladder = parseFloat(fraction_of_ladder) - parseFloat(NUDGE); 
          // TODO: scale the spacing of callsigns here

          ex_diff = parseFloat(ex) - parseFloat(fraction_of_ladder);
    
        }
      });

      let y_coord_prime = (ctx.canvas.height / sf) * fraction_of_ladder;

      if (y_coord_prime >= (ctx.canvas.height / sf) * 0.99) {
        y_coord_prime = (ctx.canvas.height / sf) * 0.99;
        fraction_of_ladder = 0.99;
      } 
      ctx.lineWidth = 1;
      // Draw line
      ctx.beginPath();
      ctx.lineWidth = 1;
      ctx.strokeStyle = tag_color;
      ctx.fillStyle = tag_color;
      
      
      ctx.moveTo(border + (multiplier * 3), y_coord);
      ctx.lineTo(border + (multiplier * 15), y_coord_prime);
      ctx.stroke();
      ctx.closePath();

      // Draw Symbol
      drawSymbol(ctx, item["type"], multiplier, tag_color, border, y_coord_prime);

      // Draw Text
      ctx.fillStyle = tag_color;
      ctx.textBaseline = "middle";
      ctx.textAlign = "left"

      let displacement = 25;

      
      
      if (multiplier < 0) {
        displacement = 87;
        if (ladderSettings["fontSize"] === "large") {
          displacement = 89;
        }
        else if (ladderSettings["fontSize"] === "small" ) {
          displacement = 70;
        }
      }

      //Bounding Box Calculation for click tracking
      let textwidth = ctx.measureText(item["callsign"]).width;
      let textheight = parseInt(ctx.measureText("M").width) * 1.2;
      item.callsignX=border + (multiplier * displacement);
      item.callsignY=(y_coord_prime + 0.5) - textheight/2;
      item.callsignwidth=textwidth;
      item.callsignheight=textheight;

      
      //Bounding Box for click Tracking Debugging.
      // ctx.beginPath()
      // ctx.moveTo(item.callsignX,item.callsignY)
      // ctx.lineTo(item.callsignX+item.callsignwidth,item.callsignY)
      // ctx.lineTo(item.callsignX+item.callsignwidth,item.callsignY+item.callsignheight)
      // ctx.lineTo(item.callsignX,item.callsignY+item.callsignheight)
      // ctx.stroke();
      // ctx.closePath();
      let d = item["delay"];

      if (item["status"] === "ETA") {
        // This is going to be drawn on the left side
        ctx.fillText(item["callsign"], border + (multiplier * displacement), y_coord_prime + 0.5)
      } else {
        // Logic to draw delay

        // This is going to be drawn on the right side
        ctx.fillText(item["callsign"], border + (multiplier * displacement), y_coord_prime + 0.5)
      
        if (ladderSettings["showDelay"] === true) {
          // If delay is greater than 0.5 or less than -1 , draw delay
          if (d >= 0.5) {
            // Start drawing past the width of 7 characters 
            let callsign_width = ctx.measureText("XXXXXXXX");
            var d_color = delay_color["GREEN"];
            if (d > 5 && d <= 10) {
              d_color = delay_color["YELLOW"]
            } else if (d > 10 && d <= 14) {
              d_color = delay_color["ORANGE"]
            } else if (d >= 15) {
              d_color = delay_color["RED"]
            }

            ctx.fillStyle = d_color;
            if (d < 100) {
              ctx.fillText(zeroPad(String(item["delay"]), 2), border + callsign_width.width + (multiplier * displacement), y_coord_prime + 0.5)
            } else {
              ctx.fillText("++", border + callsign_width.width + (multiplier * displacement), y_coord_prime + 0.5)
            }

          }
          if (d <= -1 && d > -10) {
            let callsign_width = ctx.measureText("XXXXXXXX");
            ctx.fillStyle = delay_color["GRAY"]
            ctx.fillText("-" + String(-item["delay"]), border + callsign_width.width + (multiplier * displacement), y_coord_prime + 0.5)
          }
          if (d <= -10) {
            let callsign_width = ctx.measureText("XXXXXXXX");
            ctx.fillStyle = delay_color["GRAY"]
            ctx.fillText("-X", border + callsign_width.width + (multiplier * displacement), y_coord_prime + 0.5)
          }
        }
      }


      // 0 -> 1, 180 -> 200 0.02
      if (multiplier < 0) {
        exclusion_left.add(parseFloat(fraction_of_ladder));
      } else {
        exclusion_right.add(parseFloat(fraction_of_ladder));
      }
    });
  }, [ladderSettings]);


  const zeroPad = (input, num_chars) => {
    var ret = "";
    if(input<1) input = 1;
    while (ret.length + input.length < num_chars) {
      ret += "0";
    }
    ret += input;
    return (ret);
  }

  useEffect(() => {
    setFlightState(props.etas);
  }, [props.stream, props.etas]);

  useEffect(() => {
    const createHiDPICanvas = (h, subtract_height, ratio) => {
      const can = canvasRef.current;
      const context = can.getContext("2d");
      if (!ratio) { ratio = PIXEL_RATIO(context); }
      
      // 310 px width for including longest possible callsign and delay amt
      let timeline_width = 325;
      if (ladderSettings["fontSize"] === "large") {
        timeline_width = 350;
      } else if (ladderSettings["fontSize"] === "small") {
        timeline_width = 300;
      }
      const w = timeline_width;
      can.width = timeline_width * ratio;
      can.height = (h * ratio) - (subtract_height * ratio);
      can.style.width = (w) + "px";
      //can.style.width = "100%";
      can.style.height = (h - subtract_height) + "px";
      // can.style.margin = "0";
      can.getContext("2d").setTransform(ratio, 0, 0, ratio, 0, 0);
      return can;
    }

    //Create canvas with the device resolution.
    //145 pixels for the bottom of the timelines for labels and also navbar
    var ctx = createHiDPICanvas(window.innerHeight, 135).getContext('2d');
    ctx.scale(SCALE_FACTOR, SCALE_FACTOR)
  }, [ladderSettings,windowSize]);
  
  useEffect(() => {
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');

      // Clear for redrawing
      context.clearRect(0, 0, canvas.width, canvas.height);

      var factor = SCALE_FACTOR * PIXEL_RATIO(context);

      // Set font
      let font_size = 13;
      if (ladderSettings["fontSize"] === "large") {
        font_size = 15;
      } else if (ladderSettings["fontSize"] === "small") {
        font_size = 10;
      }

      context.font = `${font_size}px Roboto Mono`

      // Define ladder boundaries based on 
      let left_ladder_bound = (context.canvas.width / (2 * factor)) - 20;
      let right_ladder_bound = (context.canvas.width / (2 * factor)) + 20;

      if (ladderSettings["timeFormat"] === "mm") {
        left_ladder_bound = (context.canvas.width / (2 * factor)) - 12;
        right_ladder_bound = (context.canvas.width / (2 * factor)) + 12;
      }


      // Draw ladder boundary
      buildLadder(context, factor, left_ladder_bound, right_ladder_bound, ladderSettings["ladderLength"] * 60);

      if (flightState.length > 0) {
        drawAirplanes(context, factor, flightState, left_ladder_bound, right_ladder_bound, ladderSettings["ladderLength"] * 60, OVERLAP_FRAC);
      }
  }, [flightState, ladderSettings, drawAirplanes, buildLadder,windowSize]);

  

  const show_modal = () => {
    setShowModal(true);
  }

  const close_modal = () => {
    setShowModal(false)
  }

  const show_AircraftModal = () => {
    setShowAircraftModal(true);
  }

  const closeAircraftModal = () => {
    setShowAircraftModal(false);
    props.fetch();
    
  }

  const redraw = () => {
    props.fetch();
  }

  function isClicked(x,y, item) {
     if(x>=item.callsignX && x <=(item.callsignX +item.callsignwidth) && y>=item.callsignY && y<= (item.callsignY+item.callsignheight)) return true;
    return false;
  }

  function getMousePos(evt) {
        var rect = canvasRef.current.getBoundingClientRect();
        var X = (evt.clientX - rect.left) / SCALE_FACTOR; 
        var Y = (evt.clientY - rect.top) / SCALE_FACTOR;  

        X = Math.ceil(X);
        Y = Math.ceil(Y);
     
        return {
            x: X,
            y: Y
        };
    }

  const canvasClick = (e) => {
    var pos = getMousePos(e);
       
    flightState.forEach( (item ) => {
      
      if(isClicked(pos.x,pos.y,item) === true ) {
        if(item.status!=="ETA") {
          setAircraftParms(item);          
          show_AircraftModal();

        }
        // Item Clicked
      }
    });
  }

  const MENU_ID = "contextID";

  const { show } = useContextMenu({
      id: MENU_ID
  });

  const canvasContext = (e) => {
    var pos = getMousePos(e);
    flightState.forEach( (item ) => {      
      if(isClicked(pos.x,pos.y,item) === true ) {
        
         if(AuthService.hasPermission('facility.'+props.facility+'.admin') ||AuthService.hasPermission('global.admin') ) { 
          show(e, {props: item});
         }
        
            
      }
    });
  }

  async function handleItemClick({ event, props, triggerEvent, data }){
    

    if(event.nativeEvent.target.innerText==="Schedule") {
      // eslint-disable-next-line no-unused-vars
      const res = await APIService.apiGET(`${process.env.REACT_APP_API_URL}/api/edct/schedule?callsign=${props.callsign}`);      
      redraw();

    }

  }
  

  useEffect(() => {
    if (props.prevSettings !== undefined) {
      setLadderSettings(props.prevSettings);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div style={{ textAlign: "center" }}>
      <canvas ref={canvasRef} onClick={canvasClick} onContextMenu={canvasContext}></canvas>
      <div className="current_time_baseline"></div>
      <table className="tmu-ladder">
        <tbody>
          <tr>
            <td colSpan="3">{props.fix} <div className="bottom_box" onClick={show_modal}><span className="timeline_num">{props.order}</span></div> {props.fix}</td>
          </tr>
          <tr>
            <td colSpan="3">All <span className="bottom_status">MFX</span> All</td>
          </tr>
        </tbody>
      </table>
      <TMUModal show_func={showModal} close_func={close_modal} fix={props.fix} setLadderSettings={s => updateSettings(s)}  prevSettings={props.prevSettings} />
      <TMUAircraftModal show_func={showAircraftModal} close_func={closeAircraftModal} parms={aircraftParms} />
      <TMUAircraftContextMenu menuID={MENU_ID} handleItemClick={handleItemClick} parms={aircraftParms} />

      
      
    </div>
  );


};

export default TMULadder;