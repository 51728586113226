import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import vader from '../NWRVader.png'

const NotFoundPage = () => {
  return (
    <Container>
      <div style={{ display: "flex", alignItems: "center", minHeight: "100vh", position: "fixed" }}>
        <div>
          <img src={vader} alt="NickyVader" style={{marginBottom: "20px"}}></img>
          <h3 style={{ color: "lightgray" }} >404 Error</h3>
          <h1 style={{ color: "white" }} >May the Flow Be With You</h1>
          <p><Link to="/">Return to home</Link></p>
        </div>
      </div>
    </Container>
  )
}

export default NotFoundPage;