import { Container, Table, Row, Col, Button, ButtonGroup, Form } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import CreateFacilityModal from './CreateFacilityModal';
import AuthService from '../services/AuthService';
import APIService from '../services/APIService';

const ConfigFacilities = (props) => {
    const [facilities, setFacilities] = useState([]);
    const [editMode, setEditMode] = useState([]);
    const [facilityIdent, setFacilityIdent] = useState([]);
    const [facilityName, setFacilityName] = useState([]);
    const [showModal, setShowModal] = useState(false);
   
    const enableEditMode = (idx) => {
      setFacilityIdent(facilities[idx]["identifier"])
      setFacilityName(facilities[idx]["name"])
      
  
      editMode[idx] = true;
      setEditMode([...editMode]);
    };

    const disableEditMode = (idx) => {
      editMode[idx] = false;
      setEditMode([...editMode]);
    }

    const saveEdits = async (idx) => {
      try { 
        // eslint-disable-next-line no-unused-vars
        const res = await APIService.apiPOST(`${process.env.REACT_APP_API_URL}/api/config/facilities/update`, {
          "id": facilities[idx]["id"],
          "identifier": facilityIdent,
          "name": facilityName,
          
        });
    
        disableEditMode(idx);
        getFacilities();
      }
      catch (err) {
        console.log(err);
      }
    }
  
    const deleteFacility = async (idx) => {

      const r = window.confirm("Do you really want to Delete "+facilities[idx].name+"?"); 
        if(r === true) {
          try {
            // eslint-disable-next-line no-unused-vars
            const res = await APIService.apiPOST(`${process.env.REACT_APP_API_URL}/api/config/facilities/delete`, {
              "id": facilities[idx]["id"]
            });
        
            disableEditMode(idx);
            getFacilities();
          }
          catch (err) {
            console.log(err);
          }
        }     
    }
  
    const createFacility = async (identifier, name) => {
      try {
        // eslint-disable-next-line no-unused-vars
        const res = await APIService.apiPOST(`${process.env.REACT_APP_API_URL}/api/config/facilities/create`, {
          identifier,
          name
        });
    
        close_modal();
        getFacilities();
      }
      catch (err) {
        console.log(err);
      }
    };
  
    const getFacilities = async () => {
      try { 
        const res = await APIService.apiGET(`${process.env.REACT_APP_API_URL}/api/config/facilities`);
        let init_edit_mode = [];
        let out = [];
        res.data.forEach((f) => {
          if(AuthService.hasPermission("facility."+f["id"]+".admin") || AuthService.hasPermission("admin.facilities")) {
            init_edit_mode.push(false)
            out.push(f);
          }
        })
          
    
        setEditMode(init_edit_mode);
        setFacilities(out);
      
    
      }
      catch (err) {
        console.log(err.response.status);
        if(err.response.status===401) {
          console.log("refreshing?");
          await AuthService.refreshTokens();

        }
        else {
          console.log(err);
        }
        
      }     
    }
  
    const show_modal = () => {
      setShowModal(true);
    }
  
    const close_modal = () => {
      setShowModal(false)
    }  
  
    useEffect(() => {
      getFacilities();
    }, [])
  
    return (
      <Container className="mono">
        <Row>
          <Col>
            <h2 style={{color: "white", marginTop:"10px"}}>Facilities</h2>
          </Col>
          <Col style={{textAlign:"right"}}>
            {
              AuthService.hasPermission("admin.facilities") ? ( <div>
                <Button variant="primary" style={{marginTop: "10px"}} onClick={show_modal}>Add Facility</Button> {' '}
                </div>
              ) : ( <></> )
            }
            
          </Col>
        </Row>
        <Row>
          <Col>
            <Table striped bordered hover variant="dark">
              <thead>
                <tr>
                  <th>Identifier</th>
                  <th>Name</th>                 
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {facilities.map((facility, idx) => {
                  if (editMode[idx]) {
                    return (
                      <tr key={idx}>
                        <td><Form.Control type="text" placeholder="Facility Identifier" defaultValue={facility["identifier"]} onChange={e => setFacilityIdent(e.target.value)}/></td>
                        <td><Form.Control type="text" placeholder="Facility Name" defaultValue={facility["name"]} onChange={e => setFacilityName(e.target.value)}/></td>                        
                        <td>
                        <ButtonGroup aria-label="save group">
                          <Button variant="success" size="sm" onClick={() => saveEdits(idx)}>Save</Button>{' '}
                          <Button variant="warning" size="sm" onClick={() => disableEditMode(idx)}>Cancel</Button>
                        </ButtonGroup>
                        </td>
                      </tr>
                    )
                  } else {
                    return (
                      <tr key={idx}>
                        <td>{facility["identifier"]}</td>
                        <td>{facility["name"]}</td>
                        
                        <td>
                          <ButtonGroup aria-label="edit group">
                            {
                              
                              AuthService.hasPermission("facility."+facility["id"]+".admin") ? ( <div>
                                <Button className="me-1" variant="primary" size="sm" href={ "/facilityAdmin/"+facility["identifier"] }>Manage</Button> 
                                
                                </div>

                              ) : ( 

                                <></>
                              )  }
                              
                              {                            
                              
                              AuthService.hasPermission("admin.facilities") ? (
                                <div>
                                  <Button className="me-1" variant="warning" size="sm" onClick={() => enableEditMode(idx)}>Edit</Button> 
                                  <Button variant="danger" size="sm" onClick={() => deleteFacility(idx)}>Delete</Button>

                                </div>
                              ) : ( 

                                <></>
                              )
                              

                            }
                            
                      
                            
                          </ButtonGroup>
                        </td>
                      </tr>
                    )
                  }
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
        <CreateFacilityModal show_func={showModal} close_func={close_modal} createFacility={(i, n) => createFacility(i, n)}/>
      </Container>
    )
  
  };
  
  export default ConfigFacilities;
  