// eslint-disable-next-line no-unused-vars
import { Container, Table, Row, Col, Button, ButtonGroup, Form } from 'react-bootstrap';
import { useEffect, useState } from 'react';

import AuthService from '../services/AuthService';
import APIService from '../services/APIService';
import { Select2 } from "select2-react-component";
import 'select2-component/dist/select2.min.css';

const ConfigUsers = (props) => {
    const [users, setUsers] = useState([]);
    
    const [facilities, setFacilities] = useState([]);
    
    
    

    const syncUser = async(id) => {
      try {
        
        const res = await APIService.apiPOST(`${process.env.REACT_APP_API_URL}/api/users/sync`, {
          "cid":id,
        });

        getUsers();

        if (res.data) {


        }


      }
      catch (err) {
        console.log(err.response.status);
        if(err.response.status===401) {
          console.log("refreshing?");
          await AuthService.refreshTokens();

        }
        else {
          console.log(err);
        }
        
      }    
      

    }
  
    const deleteUser = async (idx) => {

      const r = window.confirm("Do you really want to Delete "+users[idx].name+"?"); 
        if(r === true) {
          try {
            // eslint-disable-next-line no-unused-vars
            // const res = await APIService.apiPOST(`${process.env.REACT_APP_API_URL}/api/config/facilities/delete`, {
            //   "id": users[idx]["id"]
            // });
        
            
            getUsers();
          }
          catch (err) {
            console.log(err);
          }
        }     
    }
  
    const getFacilities = async () => {
      try { 
        const res = await APIService.apiGET(`${process.env.REACT_APP_API_URL}/api/config/facilities`);
      
        
        

        let fac = new Set();

        // eslint-disable-next-line array-callback-return
        res.data.map((item,idx) => { 
          fac.add( { value: item.id, label: item.identifier} );
        });    
      
        setFacilities(Array.from(fac));
        console.log(fac);

        
      }
      catch (err) {
        console.log(err.response.status);
        if(err.response.status===401) {
          console.log("refreshing?");
          await AuthService.refreshTokens();

        }
        else {
          console.log(err);
        }
        
      }     
    }
    
  
    const getUsers = async () => {
      try { 
        const res = await APIService.apiGET(`${process.env.REACT_APP_API_URL}/api/users/users`);

    
        
        setUsers(res.data);
      
    
      }
      catch (err) {
        console.log(err);
        console.log(err.response.status);
        if(err.response.status===401) {
          console.log("refreshing?");
          await AuthService.refreshTokens();

        }
        else {
          console.log(err);
        }
        
      }     
    }

    const onLoad = async () => {
      await getFacilities();
    }

  
    
    useEffect(() => {
      onLoad();

      
      getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  
    return (
      <Container className="mono">
        <Row>
          <Col>
            <h2 style={{color: "white", marginTop:"10px"}}>Users</h2>
          </Col>
          
        </Row>
        <Row>
          <Col>
            <Table striped bordered hover variant="dark">
              <thead>
                <tr>
                  <th>CID</th>
                  <th>Name</th> 
                  <th>Roles</th>  
                  <th>Facilities</th>              
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user, idx) => {
                  
                  return (
                    <tr key={idx}>
                      <td>{user["id"]}</td>
                      <td>{user["name"]}</td>
                      <td>{
                        user["roles"].length >0 ? (<div>✅</div> ) : (<div></div> )
                      }
                        </td>
                      <td>
                      <div className="stream-cfg"><Select2 disabled="true" multiple="true" value={Array.from(user["facilities"])} data={ facilities }  ></Select2></div>
                      </td>
                      
                      <td>
                        <ButtonGroup aria-label="edit group">
                          {
                            
                                                  
                            
                            AuthService.hasPermission("admin.users") ? (
                              <div>
                                {/* <Button className="me-1" variant="warning" size="sm" onClick={() => enableEditMode(idx)}>Edit</Button>  */}
                                <Button variant="info" size="sm" onClick={() => syncUser(user["id"])}>Sync</Button>
                                <Button variant="danger" size="sm" onClick={() => deleteUser(idx)}>Delete</Button>

                              </div>
                            ) : ( 

                              <></>
                            )
                            

                          }
                          
                    
                          
                        </ButtonGroup>
                      </td>
                    </tr>
                  )
                  
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
        
      </Container>
    )
  
  };
  
  export default ConfigUsers;
  