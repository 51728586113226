 // eslint-disable-next-line no-unused-vars
 import { useEffect, useState} from 'react';
 import { useParams } from 'react-router-dom';
 // eslint-disable-next-line no-unused-vars
import { Container, Button, ButtonGroup, Modal, Form, Row, Col, Table, Accordion } from 'react-bootstrap';
import AuthService from '../services/AuthService';
import APIService from '../services/APIService';






const FacilityAdmin = (props) => {

    let { facilityID } = useParams();

    //const [facilities, setFacilities] = useState([]);
    const [facility, setFacility] = useState({});
    // eslint-disable-next-line no-unused-vars
    const [admins, setAdmins] = useState([]);
    const [members, setMembers] = useState([]);
    const [airports, setAirports] = useState([]);




    const verifyAuth = async (f) => {

        console.log(f);

        console.log("facility."+f+".admin");
        console.log(AuthService.permissions());

        console.log(AuthService.hasPermission("facility."+f+".admin"));
        if(!(AuthService.hasPermission("facility."+f+".admin"))) {
            window.location="/";
            
        }


    }

    const getAirports = async (f) => {
        try { 
            const res = await APIService.apiGET(`${process.env.REACT_APP_API_URL}/api/config/airports`);
          
            let output=[];


          
            

            // eslint-disable-next-line no-unused-vars
            const junk = res.data.map((a, idx) => {
                if(a["facility_id"]===f) {
                    output.push(a);

                }
                return ""
            });

            setAirports(output);
      
        }
        catch (err) {
          console.log(err.response.status);
          if(err.response.status===401) {
            console.log("refreshing?");
            await AuthService.refreshTokens();
  
          }
          else {
            console.log(err);
          }
          
        }     
    }


    const getFacilities = async () => {
        try { 
            const res = await APIService.apiGET(`${process.env.REACT_APP_API_URL}/api/config/facilities`);
          
      
          
            //setFacilities(res.data);

            // eslint-disable-next-line no-unused-vars
            const junk = res.data.map((f, idx) => {
                if(f["identifier"]===facilityID) {
                    setFacility(f);
                    verifyAuth(f["id"]);
                    getAdmins(f["id"]);
                    getMembers(f["id"]);
                    getAirports(f["id"]);

                }
                return ""
            });
      
        }
        catch (err) {
          console.log(err.response.status);
          if(err.response.status===401) {
            console.log("refreshing?");
            await AuthService.refreshTokens();
  
          }
          else {
            console.log(err);
          }
          
        }     
    }

    const getAdmins = async (x) => {
        try { 
            const res = await APIService.apiGET(`${process.env.REACT_APP_API_URL}/api/users/admin?facility=${x}`);
          
            console.log(res);

            setAdmins(res.data);
            

            
      
        }
        catch (err) {
          console.log(err.response.status);
          if(err.response.status===401) {
            console.log("refreshing?");
            await AuthService.refreshTokens();
  
          }
          else {
            console.log(err);
          }
          
        }     
    }

    const getMembers = async (x) => {
        try { 
            const res = await APIService.apiGET(`${process.env.REACT_APP_API_URL}/api/users/members?facility=${x}`);
          
            console.log(res);

            setMembers(res.data);
            

            
      
        }
        catch (err) {
          console.log(err.response.status);
          if(err.response.status===401) {
            console.log("refreshing?");
            await AuthService.refreshTokens();
  
          }
          else {
            console.log(err);
          }
          
        }     
    }

    


    useEffect(() => {    
        
        

        getFacilities();
        
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])


    return (
        <Container className="profile-body">
            <h5 className="edct-header page-header"> {facility["name"]}</h5>

            


            <Container className="mt-5">
                 <Accordion defaultActiveKey="0">
                 <Accordion.Item eventKey="0"> 
                        <Accordion.Header>
                            Airports
                        </Accordion.Header> 
                        <Accordion.Body>
                           
                        <Table responsive striped variant="dark"> 
                             <tbody> 
                            {
                                      airports.map((a,idx) => {
                                        return (
                                            <tr key={idx}><td>{a.icao} - {a.name}</td>
                                            
                                            <td><Button className="me-1" variant="primary" size="sm" href={ "/airportAdmin/"+a.icao }>Manage</Button> </td></tr>
                                            )
                                        })
                            }
                             </tbody> 
                        </Table>

                        </Accordion.Body>

                    </Accordion.Item> 

                    
                    <Accordion.Item eventKey="1"> 
                        <Accordion.Header>
                            Members
                        </Accordion.Header> 
                        <Accordion.Body>
                           
                        <Table responsive striped variant="dark"> 
                             <tbody> 
                            {
                                members.map((user,idx) => {
                                return (
                                    <tr key={idx}><td>{user.id} - {user.name}</td></tr>
                                    )
                                })
                            }
                             </tbody> 
                        </Table>

                        </Accordion.Body>

                    </Accordion.Item> 
                    


                </Accordion> 
                
                

            </Container>

            


            



        </Container>
        

    )




};

export default FacilityAdmin;


