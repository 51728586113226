import axios from "axios";
import AuthService, { getCookie } from './AuthService';



class APIService {

    async apiGET(url,recurse=true) {
        try {
            const res = await axios.get(url);
            return res;
        }
        catch (err) {            
            console.log(err.response);
            console.log(err);
            if(err.response.data !== undefined && err.response.data.msg !== undefined ) {
                let str = err.response.data.msg;
                if(str.startsWith("Token has expired")) {
                    if(recurse) {
                        await AuthService.refreshTokens();
                        this.apiGET(url,false);
                    }
                }
                else throw(err);
            }            
        }
    }

    async apiPOST(url,data,recurse=true) {
        const csrftoken = getCookie("csrf_access_token");
        const csrf_header = { headers: { 'X-CSRF-TOKEN':csrftoken}};   

        try {
            const res = await axios.post(url,data,csrf_header);
            return res;
        }
        catch (err) {
            
            let str = err.response.data.msg;
            if (str.startsWith('Missing')){
                //this.login();
                window.alert(str);
                console.log(err.response);       
            }
            else if(str.startsWith("CSRF double")){
                if(recurse) {
                    await AuthService.refreshTokens();
                    this.apiPOST(url,data,false);
                }
            }            
            else{
                throw(err);                       
            }
        }

        
    }
}

export default new APIService();

