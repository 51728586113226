import TMULadder from './TMULadder';
import TMUModal from './TMUModal';
import { Container, Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useDebugValue, useEffect, useState } from 'react';
import APIService from '../services/APIService';

const TMUWrapper = () => {
  let { icao } = useParams();
  const [streams, setStreams] = useState([]);
  const [facility, setFacility] = useState();

  const fetchStreams = async (icao) => {
    try {
      const res = await APIService.apiGET(`${process.env.REACT_APP_API_URL}/api/streams?icao=${icao}`);
      setStreams(res.data["streams"]);

      const ares = await APIService.apiGET(`${process.env.REACT_APP_API_URL}/api/config/airports`);
      for (const item of ares.data) {
        
        
        if(item.icao==icao.toUpperCase()) {
          
          setFacility(item.facility_id);
        }
        
      }
      
      

    }
    catch (err) {
      if(err.response !== undefined) {
        if(err.response.status===404) {
          window.location.href="/404";
        }
      }
    }
    
  }

  // Use icao to reach out to server and grab streams
  useEffect(() => {
    document.addEventListener('contextmenu', function(event){
      event.preventDefault();})
    
    fetchStreams(icao);
    // eslint-disable-next-line no-unused-vars
    const id = setInterval(() => fetchStreams(icao), 15000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  let prevSettings = {}

  const updateLocalStorage = (settings, fix) => {
    //console.log("Saving "+fix+" to local storage")
    localStorage.setItem(fix + "LadderSettings", JSON.stringify(settings));
  }

  return (
    <Container className="mono-font" style={{maxWidth: "100vw", margin:"0"}}>
      <TMUModal/>
      <Row className="tmu-container flex-nowrap" style={{margin: "0"}}>
        {Object.keys(streams).map((stream, idx) => { 
          if (localStorage.getItem(stream + "LadderSettings")) { 
            //console.log(localStorage.getItem(stream + "LadderSettings"));            
            prevSettings = JSON.parse(localStorage.getItem(stream + "LadderSettings")); 
          } else {
            //console.log("could not find " + stream + "LadderSettings");
            prevSettings = undefined;
          }
          return (
            <Col key={idx} style={{paddingLeft: "5px", paddingRight: "5px"}}>
              
              <TMULadder stream={stream} order={idx+1} facility={facility} fix={stream} etas={streams[stream]} fetch={() => { fetchStreams(icao) }} updateCallback={(settings, fix) => updateLocalStorage(settings, fix)} prevSettings={prevSettings} />
            </Col>
          )
        })}
      </Row>
    </Container>    
  );
};

export default TMUWrapper;