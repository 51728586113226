import axios from "axios";

export function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }


class AuthService {


    async login() {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/redirect_url`);
        if(res.data.url) {             
            window.location.href = res.data.url;
        }

    }

    async loginLocal(cid) {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/backdoor/${cid}`);
        if (response.data.cid) {
            localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
    }

    async refreshUser() {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/user`);
            if(res.data.cid) {
                localStorage.setItem("user", JSON.stringify(res.data));   
            }
        }
        catch (err) {
            if(err.response.status===401) {
                await this.refreshTokens();
            }
        }
        
    }

    async verify(code) {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/verify?code=${code}`);
        if(res.data.cid) {
            localStorage.setItem("user", JSON.stringify(res.data));
            window.location.href="/"        
        }
    }

    async logout() {
        try {
            await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/logout`)
            localStorage.removeItem("user");
        }
        catch(err) {
            localStorage.removeItem("user");
        }
      
    }

    cid() {
        if(!this.loggedIn()) return "";
        return JSON.parse(localStorage.getItem('user')).cid;
    }

    loggedIn() {        
        return localStorage.getItem('user')!== null;
    }

    getCurrentUser() {
        if(!this.loggedIn()) return undefined;
        return JSON.parse(localStorage.getItem('user'));
    }

    userName() {
        if(!this.loggedIn()) return "";
        return JSON.parse(localStorage.getItem('user')).name_first;
    }

    userFullName() {
        if(!this.loggedIn()) return "";
        return JSON.parse(localStorage.getItem('user')).name_first + " " + JSON.parse(localStorage.getItem('user')).name_last;
    }

    hasRole(role) {
        if(!this.loggedIn()) return false;
        return(JSON.parse(localStorage.getItem('user')).roles.filter(e => e.name === role).length>0 );
    }

    hasPermission(permission) {
        if(!this.loggedIn()) return false;

        if(permission.startsWith("facility")) {
            if(JSON.parse(localStorage.getItem('user')).permissions.filter(e => e.name === "global.admin").length>0) return true;
        }
        
        return(JSON.parse(localStorage.getItem('user')).permissions.filter(e => e.name === permission).length>0 );
    }

    permissions() {
        if(!this.loggedIn()) return undefined;
        return JSON.parse(localStorage.getItem('user')).permissions;
    }

    roles() {
        if(!this.loggedIn()) return undefined;
        return JSON.parse(localStorage.getItem('user')).roles;
    }

    facilities() {
        if(!this.loggedIn()) return undefined;
        return JSON.parse(localStorage.getItem('user')).facilities;
    }

    isFacilityAdmin() {
        if(!this.loggedIn()) return false;

        
        
        const fac = this.facilities();
        for (const f of fac) {
            if(this.hasPermission("facility."+f.id+".admin")) { return true;}
        }

        return false;

    }

    isFacilityTMU() {
        if(!this.loggedIn()) return false;

        if (this.hasPermission('admin.restrictions')) return true;
        
        
        const fac = this.facilities();
        for (const f of fac) {
            if(this.hasPermission("facility."+f.id+".tmu")) { return true;}
        }

        return false;


    }
    

    async refreshTokens(refreshCSRC = false, recurseAllowed=true) {
        try {    
            let csrftoken = getCookie( refreshCSRC? "csrf_refresh_token":"csrf_access_token");  
            
            const csrf_header = { headers: { 'X-CSRF-TOKEN':csrftoken}};      
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/refresh`,{},csrf_header);            
            if(res.data.msg==="Refresh successful") {
                window.location.reload();
            }
        }
        catch (err) {
            let str = err.response.data.msg;
            if (str.startsWith('Missing')){
                //this.login();
                console.log(err.response);       
            }
            else if(str.startsWith("CSRF double")){
                if(recurseAllowed) this.refreshTokens(true,false);
            }            
            else{
                console.log(err.response);                        
            }
        }
    }
}

export default new AuthService();