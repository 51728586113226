import { useState, useEffect } from 'react';
import { Container, Table, Row, Col, Form, Button } from 'react-bootstrap';
import moment from 'moment';
import EDCTBumpModal from './EDCTBumpModal';
import useInterval from './UseInterval';
import APIService from '../services/APIService';
import ReactTooltip from 'react-tooltip';
import { useParams } from 'react-router-dom';

const EDCTTable = (props) => {
  const { airport } = useParams();
  
  const [departures, setDepartures] = useState([]);
  const [airports, setAirports] = useState([]);
  const [unavailable, setUnavailable] = useState([]);
  const [swapOptions, setSwapOptions] = useState([]);
  const [swapActive, setSwapActive] = useState(undefined);
  const [showBump, setShowBump] = useState(false);
  const [edctForm, setEdctForm] = useState({});
  const [timeStatus, setTimeStatus] = useState({});

  const sortEDCT = (a,b) => {
    if(!(a.confirmed && b.confirmed)) return 0;
    return moment.utc(a.edct).unix() - moment.utc(b.edct).unix();
  }

  const getDepartures = async () => {
    try {
      const res = await APIService.apiGET(`${process.env.REACT_APP_API_URL}/api/departures?airport=${airport}`);
      let tem = new Set();
      res.data.forEach(dep => { if(dep.confirmed) tem.add(dep.arrival) })
      let airports = Array.from(tem); 
      setAirports(airports);
      setDepartures(res.data.sort(sortEDCT));
      ReactTooltip.rebuild();
    }
    catch (err) {
      if(err.response !== undefined) {
        if(err.response.status===404) {
          window.location.href="/404";
        }


      }
    }
    
  }

  const getUnavailable = async ()=> {
    try {
      const res = await APIService.apiGET(`${process.env.REACT_APP_API_URL}/api/edct/unavailable?airport=${airport}`);
      setUnavailable(res.data);
    }
    catch (err)
    {
      console.log(err);
    }
    
  }

  const refDepartures = () => {
    return departures;
  }

  const formatTime = (t) => {
    return moment.utc(t).format("HHmm");
  }


  const updateTimeStatus = ()=> {
    let time_status = {}
    if (refDepartures()) {
      let current_utc = moment.utc()

      refDepartures().filter( dep=> dep.edct != null).forEach((edct, index) => {
        
        let edct_date = moment.utc(edct.edct);     

        let difference_min = edct_date.diff(current_utc, "minutes")
        let difference_sec = edct_date.diff(current_utc, "seconds") - (difference_min * 60)
        let difference_min_signed = difference_min;

        let status = ""
        let status_color = ""

        if (difference_min < 0 || difference_sec < 0) {
          difference_min = -difference_min
          difference_sec = -difference_sec

          if (difference_min < 10) {
            difference_min = "0" + difference_min
          }

          if (difference_sec < 10) {
            difference_sec = "0" + difference_sec
          }

          status = "-" + (difference_min).toString() + ":" + (difference_sec).toString();
          status_color = "orange"
        } else {
          if (difference_min < 10) {
            difference_min = "0" + difference_min
          }

          if (difference_sec < 10) {
            difference_sec = "0" + difference_sec
          }
          
          status = difference_min.toString() + ":" + difference_sec.toString();
        }

        if (difference_min_signed <= -2) {
          status = "EXPIRED"
          status_color = "red"
        }
        edct.status=status;
        edct.status_color=status_color;
        
        time_status[edct.callsign] = {
          status,
          status_color
        }
      })               
    }
     
    setTimeStatus(time_status);  
  }

  useEffect(() => {
    try {
      getDepartures();
      getUnavailable();
      
      updateTimeStatus();
      setInterval(getDepartures, 10000);

    } catch(e) {
      
      console.log(e);
    }
    ReactTooltip.rebuild();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useInterval(() => {
    updateTimeStatus();

  },1000);

  

  const refreshExpiredEDCT= async (callsign)=> {
    await APIService.apiGET(`${process.env.REACT_APP_API_URL}/api/edct/request?callsign=${callsign}&pedct=0`);
    getDepartures();
    getUnavailable();
  }

  const removeDeparture= async (callsign) =>{
    await APIService.apiGET(`${process.env.REACT_APP_API_URL}/api/edct/remove?callsign=${callsign}`);
    getDepartures();
    getUnavailable();
  }

  const confirmEDCT= async (callsign) =>{
    await APIService.apiGET(`${process.env.REACT_APP_API_URL}/api/edct/confirm?callsign=${callsign}`);
    getDepartures();
    getUnavailable();    
  }

  const EDCTRequest = async (callsign, proposed = edctForm[`edct_${callsign}`], requiresConfirm = true) => {  
    if(proposed === undefined ) return;
    let urlconfirm="";
    if(!requiresConfirm) urlconfirm="&confirm=1";
    await APIService.apiGET(`${process.env.REACT_APP_API_URL}/api/edct/request?callsign=${callsign}&pedct=${proposed}${urlconfirm}`);
    getDepartures();
    getUnavailable();
  }

  const handleInputChange =(e) =>{
    let value = e.target.value;
    let name = e.target.name;

    setEdctForm(prevState => {
      return {...prevState, [name]: value }
    }); 

  }

  const availableSlots = (icao) => {
    let current_utc = moment.utc();
    let slots = [];
    let search = moment.utc();
    let count=0;
    if (unavailable[icao]===undefined) return slots;
    let times = unavailable[icao].map((i) => moment.utc(i).format("HHmm"));
        
    while(count<5) {
      let searchString = search.format("HHmm");
      if( times===undefined || !times.includes(searchString)) 
      {
         slots.push(search.diff(current_utc,'minutes'));
         count++;
      }
      search = search.add(2,'minutes');      
    }
    return slots;
  }

  const SwapAvailable = ({callsign, icao}) => {
    if(swapActive !== undefined ) return null; 
    if(timeStatus[callsign] !== undefined && timeStatus[callsign].status==="EXPIRED") return null;
    var out = null;
    departures.filter( dep=>  (dep.edct!=null && dep.confirmed===true)).forEach((dep) => {
      if(dep.callsign !== callsign && dep.arrival === icao && timeStatus[dep.callsign] !== undefined && timeStatus[dep.callsign].status!=="EXPIRED") {  
        out = ( <Button variant="primary" key={dep.callsign} className="ml-2" size="sm" onClick={() => startSwap(callsign,icao) } >SWAP</Button>)
      }         
    });    
    return out;    
  }

  const SwapConfirm =({callsign}) =>{
    let matched=false;
    if(timeStatus[callsign] !== undefined && timeStatus[callsign].status==="EXPIRED") return null;
    if(swapOptions===undefined) return null;
    swapOptions.forEach((item) => {
      if(item.callsign === callsign) matched=true;
    });
    if(matched) return ( <Button variant="warning" className="ml-2" size="sm" onClick={() => executeSwap(callsign)}>Confirm</Button>)
    if(swapActive !== undefined && swapActive===callsign) return ( <Button variant="danger" className="ml-2" size="sm" onClick={() => resetSwap()}>Cancel</Button>)
    return null;
  }

  const startSwap = (callsign, icao) => {
    let times = [];
    // eslint-disable-next-line array-callback-return, no-unused-vars
    var out = departures.filter( dep=>  (dep.edct!=null && dep.confirmed===true)).map((dep, index) => {      
      if(dep.callsign !== callsign && dep.arrival === icao) {
       times.push({callsign:dep.callsign, time: dep.edct}); 
      }          
    });   
    setSwapActive(callsign);
    setSwapOptions(times);
  }

  const resetSwap = ()=> {
    setSwapActive(undefined);
    setSwapOptions([]);
  }

  const executeSwap = async (callsign) => {    
    if(swapActive !== undefined) {
      // eslint-disable-next-line no-unused-vars
      const res = await APIService.apiGET(`${process.env.REACT_APP_API_URL}/api/edct/swap?aircraft1=${callsign}&aircraft2=${swapActive}`);
    }
    resetSwap();   
    getDepartures();
    getUnavailable();    
  }

  const SlotButtons = ({ callsign, icao }) => {
    const slots = availableSlots(icao);
    const buttons = slots.map((slot) => {
      if(slot===0) {return (
        <Button key={slot} variant="info" size="sm" onClick={() => EDCTRequest(callsign,0,false)} >Now</Button>
      )} else {
        return (
          <Button key={slot} variant="secondary" size="sm" onClick={() => EDCTRequest(callsign,slot,false)} >+{slot}</Button>
        )
      }
    });
    return buttons;
  }

  const checkForEnter = (e,callsign) => {
    if (e.key === "Enter") {
      EDCTRequest(callsign)
    }
  }

  const show_bump = () => {
    setShowBump(true);
  }

  const hideBump = ()=>{
    getUnavailable();
    getDepartures();
    setShowBump(false);
  }

  return (
    <Container>
      
      

      {   
      <div>      
        <EDCTBumpModal show_func={showBump} close_func={hideBump} airports={airports} origin={airport}/>   
        <ReactTooltip place="top" type="dark" effect="solid" />
        </div>     
      }
    
    <Row>
      <Col>
        <h2 style={{color: "white", marginTop:"10px"}}>No Assigned EDCT</h2>
        <Table responsive variant="dark">
          <thead>
            <tr>
              <th>Callsign</th>
              <th>Destination</th>
              <th>Request EDCT</th>
            </tr>
          </thead>
          <tbody>
            {departures.filter( dep=> dep.edct == null || (dep.edct!=null && dep.confirmed===false)).map((dep, index) => {

              if (dep.edct != null) {
                return ( 
                  <tr key={index}>
                    <td>{dep.callsign}</td>
                    <td>{dep.arrival}</td>
                    <td><b style={{marginRight: "10px"}}>{formatTime(dep.edct)}</b><i data-tip="Cancel EDCT" onClick={() => removeDeparture(dep.callsign)} className="fas fa-plane-slash" style={{cursor: "pointer"}}></i><i data-tip="Confirm EDCT" onClick={() => confirmEDCT(dep.callsign)} className="float-right far fa-arrow-right" style={{cursor:"pointer", fontSize: "larger"}}></i></td>
                  </tr>
                  
                
                )
              } else {
                if(dep.restriction===0) {
                  return (
                  <tr key={index}>
                    <td>{dep.callsign}</td>
                    <td>{dep.arrival}</td>
                    <td><Button variant="danger" >GROUND STOP</Button></td>


                  </tr>

                  )
                    
                  
                }
                else
                if(dep.flightrules==="V") { return (
                  <tr key={index}>
                    <td>{dep.callsign}</td>
                    <td>{dep.arrival}</td>
                    <td><Button variant="secondary" size="sm">VFR</Button></td>


                  </tr>

                )}
                else if(dep.metered===false) { return (
                  <tr key={index}>
                    <td>{dep.callsign}</td>
                    <td>{dep.arrival}</td>
                    <td><Button variant="info" size="sm">Route Not Metered</Button></td>


                  </tr>
                )
                }

                else if(dep.routeissue===true) { return (
                  <tr key={index}>
                    <td>{dep.callsign}</td>
                    <td>{dep.arrival}</td>
                    <td><Button variant="warning" size="sm">Aircraft Route Issue</Button></td>


                  </tr>
                )
                }
                else
                {


                  return (
                    <tr key={index}>
                      <td>{dep.callsign}</td>
                      <td>{dep.arrival}</td>
                      <td><Form.Control size="sm" onChange={handleInputChange} onKeyUp={(e) => checkForEnter(e,dep.callsign)} name={`edct_${dep.callsign}`} type="text" placeholder="Wheels Up (minutes from now)" style={{width: "80%", display: "inline-block", marginRight: "10px"}} />
                        <i onClick={() => EDCTRequest(dep.callsign)} className="text-success far fa-plus-square" style={{cursor:"pointer", fontSize: "larger"}}></i>
                        <div className="btn-group mt-2" role="group">
                          <SlotButtons callsign={dep.callsign} icao={dep.arrival} />
                        </div>
                      </td>
                    </tr>
                  )
                }

              }
              
            })}
          </tbody>
        </Table>
      </Col>
      <Col>
      <Row>
          <Col><h2 style={{color: "white", marginTop:"10px"}}>Assigned EDCT</h2></Col>
          <Col className="d-flex" >{
          (airports.length>0) && (
            <Button variant="warning" className="ms-auto mt-auto mb-2" onClick={show_bump}>Bump</Button>
          )}
          </Col>

      </Row>
        
        
        
        <Table responsive variant="dark">
          <thead>
            <tr>
              <th>Callsign</th>
              <th>Destination</th>
              <th>EDCT</th>
              <th>Remaining</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {departures.filter( dep => dep.edct != null && dep.confirmed).map((edct, index) => {
              
              let status_color = "";
              let status = "";

              if (timeStatus[edct.callsign]) {
                status_color = timeStatus[edct.callsign].status_color;
                status = timeStatus[edct.callsign].status;
              }

              return (
                <tr key={index}>
                  <td>{edct.callsign}</td>
                  <td>{edct.arrival}  {edct.restriction===0 && (<Button variant="danger" size="sm" >GS</Button>)}</td>
                  <td>{formatTime(edct.edct)}</td>
                  <td style={{color: status_color}}>{status}</td>
                  <td><i data-tip="Refresh EDCT" onClick={() => refreshExpiredEDCT(edct.callsign)}className="fas fa-redo" style={{cursor: "pointer", marginRight: "10px"}}></i><i data-tip="Cancel EDCT" onClick={() => removeDeparture(edct.callsign)} className="fas fa-plane-slash" style={{cursor: "pointer"}}></i>
                    <SwapAvailable callsign={edct.callsign} icao={edct.arrival} /><SwapConfirm callsign={edct.callsign} />

                  
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </Col>
    </Row>
    
    </Container>
  )
  
}

export default EDCTTable;