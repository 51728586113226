import { Component } from 'react';
import moment from 'moment';
import { Table } from 'react-bootstrap';

class EDCTLadder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal_show: false,
      ladder: [],
    }
  }

  componentDidMount() {
    try {
      const generateLadder = async () => {
        // Get current UTC time
        let init_offset = moment.utc()

        // Generate Ladder
        let ladder = []
        for (let step = 0; step < 60; step++) {
          let offset = init_offset.add(1, 'minutes');

          let status = "edct-available";
          this.props.edcts.forEach((item, index) => {
            if (item.edct_hour === parseInt(offset.hours(), 10) && item.edct_minute === parseInt(offset.minutes(), 10)) {
              status = "edct-unavailable";
            } 
          });

          ladder.push({
            "hour": offset.hours(),
            "minute": offset.minutes(),
            "status": status
          })
        }

        this.setState({
          ...this.state,
          ladder: ladder,
        })
      };

      generateLadder();
      setInterval(generateLadder, 60000);
    } catch(e) {
      console.log(e);
    }
  }

  render() {
    // Make the table scrollable
    return (
      <div>
        <h2 className="edct-header">To {this.props.airport_name}</h2>
        <Table responsive className="edct-ladder">
          <tbody>
            <tr>
              {this.state.ladder.map((edct, idx) => {
                let ladder_class = "edct-ladder-tick " + edct.status;
                return <td className={ladder_class} key={idx}>|</td>
              })}
            </tr>
            <tr>
              {this.state.ladder.map((edct, idx) => {
                let utc_time = "";

                if (idx % 1 === 0) {
                  if (edct.hour < 10) {
                    utc_time += "0";
                    utc_time += edct.hour;
                  } else {
                    utc_time += edct.hour;
                  }

                  if (edct.minute < 10) {
                    utc_time += "0";
                    utc_time += edct.minute;
                  } else {
                    utc_time += edct.minute;
                  }
                }

                let ladder_class = "edct-ladder-middle " + edct.status;

                return (
                  <td className={ladder_class} key={idx}>{utc_time}</td>
                )
              })}
            </tr>
            <tr>
              {this.state.ladder.map((edct, idx) => {
                let ladder_class = "edct-ladder-tick " + edct.status;
                return <td className={ladder_class} key={idx}>|</td>
              })}
            </tr>
          </tbody>
        </Table>
      </div>
    );
    }

};

export default EDCTLadder;