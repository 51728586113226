
import { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { Select2 } from "select2-react-component";
import 'select2-component/dist/select2.min.css';

const CreateLadderModal = (props) => {

  const [airport, setAirport] = useState("ZZZZ");
  const [name, setName] = useState("");
  const [visible, setVisible] = useState(true);  
  const [fixes, setFixes] = useState([]);
  const [mergeFixes, setMergeFixes] = useState([]);
  const [airports, setAirports] = useState([]);
 
  


  const handleSubmit = (e) => {
    e.preventDefault();
    
    props.createLadder(airport,name,visible,fixes);
    
  }
  

  const show = () =>{
    if(props.mergeFixes!== undefined) {
      setMergeFixes(props.mergeFixes());
    }
    if(props.airports !== undefined) { setAirports(props.airports)}
    
    
  }

  return (
    <Modal onShow={show} show={props.show_func} onHide={props.close_func}>
      <Modal.Header closeButton>
        <Modal.Title>Ladder Configuration</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>Airport</Form.Label>
            <Form.Control as="select" name="airport" value={airport} onChange={e => setAirport(e.target.value)}>
              <option key="ZZZ" value="ZZZ">---Select One---</option>
              {airports.map((f, idx) => {
                return (<option key={idx} value={f.icao} >{f.icao}</option>)
              })}
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control name="name" type="text" value={name} placeholder="NAME" onChange={e => setName(e.target.value)} />
          </Form.Group> 
          <Form.Group>          
            <Form.Label>Visible</Form.Label>
            <Form.Check type="checkbox" checked={visible} onChange={e => setVisible(e.target.checked)} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Streams</Form.Label>
            <div className="stream-cfg"><Select2 multiple="true" value={fixes} data={mergeFixes} update={value => setFixes(value)} ></Select2></div>
          </Form.Group> 
          
          <Button variant="primary" type="submit">
            Add
          </Button>
        </Form>
        </Modal.Body>
    </Modal>
  );
}

export default CreateLadderModal;