import { Container, Table, Row, Col, Button, ButtonGroup, Form } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import CreateStarModal from './CreateStarModal';
import APIService from '../services/APIService';
import AuthService from '../services/AuthService';


const ConfigStars = (props) => {
  const [stars, setStars] = useState([]);
  const [editMode, setEditMode] = useState([]);
  const [starIdent, setStarIdent] = useState([]);
  const [mergeFix, setMergeFix] = useState([]);
  const [transitionFix, setTransitionFix] = useState([]);
  const [airport, setAirport] = useState([]);
  const [airports, setAirports] = useState([]);
  const [showModal, setShowModal] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [facilities, setFacilities] = useState([]);

  const enableEditMode = (idx) => {
    setStarIdent(stars[idx]["name"])
    setMergeFix(stars[idx]["fix"]["name"])
    setTransitionFix(stars[idx]["transition"] === undefined ? "" : stars[idx]["transition"]["name"])
    setAirport(stars[idx]["airport"] === undefined ? "" : stars[idx]["airport"]["icao"])

    editMode[idx] = true;
    setEditMode([...editMode]);
  };
  
  const disableEditMode = (idx) => {
    editMode[idx] = false;
    setEditMode([...editMode]);
  }

  const saveEdits = async (idx) => {
    try {
    // eslint-disable-next-line no-unused-vars
    const res = await APIService.apiPOST(`${process.env.REACT_APP_API_URL}/api/config/stars/update`, {
      "id": stars[idx]["id"],
      "name": starIdent,
      "merge_fix": mergeFix,
      "transition_fix": transitionFix,
      "airport": airport
    });
  }
  catch (err) {
    if(err.response) {
      if(err.response.status===403) {
        window.alert("You don't have permission to make that change.");

      }
    }
    
  }

    disableEditMode(idx);
    getStars();
  }

  const deleteStar = async (idx) => {
    const r = window.confirm("Do you really want to Delete "+stars[idx].name+"?"); 
        if(r === true) {
          // eslint-disable-next-line no-unused-vars
          const res = await APIService.apiPOST(`${process.env.REACT_APP_API_URL}/api/config/stars/delete`, {
            "id": stars[idx]["id"]
          });
      
          disableEditMode(idx);
          getStars();
        }    
  }

  const createStar = async (ident, airport, merge_fix, transition_fix) => {
    // eslint-disable-next-line no-unused-vars
    const res = await APIService.apiPOST(`${process.env.REACT_APP_API_URL}/api/config/stars/create`, {
      ident,
      merge_fix,
      transition_fix,
      airport
    });

    close_modal();
    getStars();
  };

  const getStars = async () => {
    const res = await APIService.apiGET(`${process.env.REACT_APP_API_URL}/api/config/stars`);

    let init_edit_mode = []
    let out = [];
            res.data.forEach((i) => {
              if(AuthService.hasPermission("facility."+i["airport"]["facility_id"]+".admin") || AuthService.hasPermission("admin.stars")) {
                init_edit_mode.push(false);
                out.push(i);
              }
            })


    

    setEditMode(init_edit_mode);
    setStars(out);
  }

  const getFacilities = async () => {
    try { 
      const res = await APIService.apiGET(`${process.env.REACT_APP_API_URL}/api/config/facilities`);   
      let out = [];
      res.data.forEach((f) => {
        if(AuthService.hasPermission("facility."+f["id"]+".admin") || AuthService.hasPermission("admin.stars")) {
            
          out.push(f);
        }
      })
      setFacilities(out);
    }
    catch (err) {
      console.log(err.response.status);
      if(err.response.status===401) {
        console.log("refreshing?");
        await AuthService.refreshTokens();  
      }
      else {
        console.log(err);
      }          
    }     
  }

  const getAirports = async () => {
    try { 
      const res = await APIService.apiGET(`${process.env.REACT_APP_API_URL}/api/config/airports`);   
      let out = [];
      res.data.forEach((i) => {
        if(AuthService.hasPermission("facility."+i["facility_id"]+".admin") || AuthService.hasPermission("admin.stars")) {
            
          out.push(i);
        }
      })
      setAirports(out);
    }
    catch (err) {
      console.log(err.response.status);
      if(err.response.status===401) {
        console.log("refreshing?");
        await AuthService.refreshTokens();  
      }
      else {
        console.log(err);
      }          
    }     
  }

  const show_modal = () => {
    setShowModal(true);
  }

  const close_modal = () => {
    setShowModal(false)
  }


  useEffect(() => {
    getFacilities();
    getAirports();
    getStars();
  }, [])

  return (
    <Container className="mono">
      <Row>
        <Col>
          <h2 style={{color: "white", marginTop:"10px"}}>STAR Configuration</h2>
        </Col>
        <Col style={{textAlign:"right"}}>
          <Button variant="primary" style={{marginTop: "10px"}} onClick={show_modal}>Add STAR</Button>{' '}
        </Col>
      </Row>
      <Row>
        <Col>
          <Table striped bordered hover variant="dark">
            <thead>
              <tr>
                <th>Identifier</th>
                <th>Airport</th>
                <th>Merge Fix</th>
                <th>Transition Fix</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {stars.map((star, idx) => {
                if (editMode[idx]) {
                  return (
                    <tr key={idx}>
                      <td><Form.Control type="text" placeholder="STAR Identifier" defaultValue={star["name"]} onChange={e => setStarIdent(e.target.value)}/></td>
                      <td>
                      <Form.Control as="select" name="airport" value={star["airport"]["icao"]} onChange={e => setAirport(e.target.value)}>
                        <option key="ZZZ" value="ZZZ">---Select One---</option>
                        {airports.map((f, idx) => {
                          return (<option key={idx} value={f.icao} >{f.icao}</option>)
                        })}
                      </Form.Control>
                      </td>

                      {/* <td><Form.Control type="text" placeholder="Airport ICAO" defaultValue={star["airport"] === undefined ? "" : star["airport"]["icao"]} onChange={e => setAirport(e.target.value)}/></td> */}
                      <td><Form.Control type="text" placeholder="Merge Fix Identifier" defaultValue={star["fix"]["name"]} onChange={e => setMergeFix(e.target.value)}/></td>
                      <td><Form.Control type="text" placeholder="Transition Fix Identifier" defaultValue={star["transition"] === undefined ? "" : star["transition"]["name"]} onChange={e => setTransitionFix(e.target.value)}/></td>
                      <td>
                      <ButtonGroup aria-label="save group">
                        <Button variant="success" size="sm" onClick={() => saveEdits(idx)}>Save</Button>{' '}
                        <Button variant="warning" size="sm" onClick={() => disableEditMode(idx)}>Cancel</Button>
                      </ButtonGroup>
                      </td>
                    </tr>
                  )
                } else {
                  return (
                    <tr key={idx}>
                      <td>{star["name"]}</td>
                      <td>{star["airport"] === undefined ? "" : star["airport"]["icao"]}</td>
                      <td>{star["fix"]["name"]}</td>
                      <td>{star["transition"] === undefined ? "" : star["transition"]["name"]}</td>
                      <td>
                        <ButtonGroup aria-label="edit group">
                          <Button variant="warning" size="sm" onClick={() => enableEditMode(idx)}>Edit</Button> {' '}
                          <Button variant="danger" size="sm" onClick={() => deleteStar(idx)}>Delete</Button>
                        </ButtonGroup>
                      </td>
                    </tr>
                  )
                }
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
      <CreateStarModal show_func={showModal} close_func={close_modal} fix={props.fix} airports={airports} createStar={(i, a, m, t) => createStar(i, a, m, t)}/>
    </Container>
  )

};

export default ConfigStars;

