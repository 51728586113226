import { useEffect, useState } from 'react';
import { Container, Button, ButtonGroup, Modal, Form, Row, Col } from 'react-bootstrap';

const TMUModal = (props) => {
  const [timeFormat, setTimeFormat] = useState("HHmm");
  const [ladderLength, setLadderLength] = useState(60);
  const [showDelay, setShowDelay] = useState(true);
  const [fontSize, setFontSize] = useState("medium");
  const [showActiveLeft, setShowActiveLeft] = useState(true);
  const [showActiveRight, setShowActiveRight] = useState(true);
  const [showProposedLeft, setShowProposedLeft] = useState(true);
  const [showProposedRight, setShowProposedRight] = useState(true);
  const [showJetLeft, setShowJetLeft] = useState(true);
  const [showJetRight, setShowJetRight] = useState(true);
  const [showTurboLeft, setShowTurboLeft] = useState(true);
  const [showTurboRight, setShowTurboRight] = useState(true);
  const [showPropLeft, setShowPropLeft] = useState(true);
  const [showPropRight, setShowPropRight] = useState(true);
  const [showVFRLeft, setShowVFRLeft] = useState(true);
  const [showVFRRight, setShowVFRRight] = useState(true);


  const handleToggleAll = () => {
    let new_setting = true;
    if (showActiveLeft && showActiveRight
          && showProposedLeft && showProposedRight
          && showJetLeft && showJetRight
          && showTurboLeft && showTurboRight
          && showPropLeft && showPropRight
          && showVFRLeft && showVFRRight) {
        new_setting = false;
    }
    setShowActiveLeft(new_setting);
    setShowActiveRight(new_setting);
    setShowProposedLeft(new_setting);
    setShowProposedRight(new_setting);
    setShowJetLeft(new_setting);
    setShowJetRight(new_setting);
    setShowTurboLeft(new_setting);
    setShowTurboRight(new_setting);
    setShowPropLeft(new_setting);
    setShowPropRight(new_setting);
    setShowVFRLeft(new_setting);
    setShowVFRRight(new_setting);
  }

  const updateSettings = () => {
    let settings_map = {
      timeFormat, ladderLength, showDelay,
      fontSize, showActiveLeft, showActiveRight,
      showProposedLeft, showProposedRight, 
      showJetLeft, showJetRight, showTurboLeft,
      showTurboRight, showPropLeft, showPropRight,
      showVFRLeft, showVFRRight
    }

    if (props.setLadderSettings) {
      props.setLadderSettings(settings_map);
    }
  }

  useEffect(() => {    

    if (props.prevSettings === undefined) {
      updateSettings();
    }
    else{
      //We have previous settings, load them.
      setTimeFormat(props.prevSettings.timeFormat)
      setLadderLength(parseInt(props.prevSettings.ladderLength))
      setShowDelay(props.prevSettings.showDelay)
      setFontSize(props.prevSettings.fontSize)
      setShowActiveLeft(props.prevSettings.showActiveLeft)
      setShowActiveRight(props.prevSettings.showActiveRight)
      setShowProposedLeft(props.prevSettings.showProposedLeft)
      setShowProposedRight(props.prevSettings.showProposedRight)
      setShowJetLeft(props.prevSettings.showJetLeft)
      setShowJetRight(props.prevSettings.showJetRight)
      setShowTurboLeft(props.prevSettings.showTurboLeft)
      setShowTurboRight(props.prevSettings.showTurboRight)
      setShowPropLeft(props.prevSettings.showPropLeft)
      setShowPropRight(props.prevSettings.showPropRight)
      setShowVFRLeft(props.prevSettings.showVFRLeft)
      setShowVFRRight(props.prevSettings.showVFRRight)
      

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  // Form Ref Callbacks
  const handleSubmit = (e) => {
    e.preventDefault();
    updateSettings();
  }

  return (
    <Modal show={props.show_func} onHide={props.close_func}>
      <Modal.Body>
        <Container className="TimelineControlsCont" style={{ background: "white", width:"300px"}}>
          <h5 className="text-center">{props.fix} Timeline</h5>
          <hr/>
          <Form onSubmit={handleSubmit}>
            <Form.Group as={Row} controlId="tlcont.TimeFormatSelect">
              <Form.Label column>Time Format</Form.Label>
              <Col>
                <Form.Control as="select" value={timeFormat} onChange={e => setTimeFormat(e.target.value)}>
                  <option value="HHmm">HHmm</option>
                  <option value="mm">mm</option>
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="tlcont.TimelineLengthInput">
              <Form.Label column>Length (min)</Form.Label>
              <Col>
                <Form.Control type="number" step="5" min="15" max="180" value={ladderLength} onChange={e => setLadderLength(e.target.value)} />
              </Col>
              <Form.Text style={{paddingLeft: "15px", color:"gray"}}>Increments of 5 between 15 and 180 minutes</Form.Text>
            </Form.Group>
            <Form.Group as={Row} controlId="tlcont.DelayDisplaySelect">
              <Form.Label column>Delay</Form.Label>
              <Col>
                <Form.Control as="select" value={showDelay} onChange={e => setShowDelay(e.target.value==="true")}>
                  <option value="true">Show</option>
                  <option value="false">Hide</option>
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="tlcont.FontSizeSelect">
              <Form.Label column>Font</Form.Label>
              <Col>
                <Form.Control as="select" value={fontSize} onChange={e => setFontSize(e.target.value)}>
                  <option value="small">Small</option>
                  <option value="medium">Medium</option>
                  <option value="large">Large</option>
                </Form.Control>
              </Col>
            </Form.Group>

            <Container style={{backgroundColor:"#eeeeee", paddingTop: "12px", paddingBottom:"5px", marginBottom: "10px"}}>
              <Row style={{marginBottom:"10px"}}>
                <Col>
                  <h6 style={{margin:"0"}}>Filter Flights:</h6>
                </Col>
                <Col className="text-right">
                  <Button size="sm" style={{fontSize:"12px"}} onClick={handleToggleAll}>Toggle All</Button>
                </Col>
              </Row>
          
              <Form.Group as={Row} controlId="tlcont.FilterDisplayChecks1">
                <Col>
                  <Form.Check type="checkbox" checked={showActiveLeft} onChange={e => setShowActiveLeft(e.target.checked)}/>
                  <Form.Check type="checkbox" checked={showProposedLeft} onChange={e => setShowProposedLeft(e.target.checked)}/>
                </Col>
                <Col className="text-center" lg={6}>
                  Active<br />
                  Proposed
                </Col>
                <Col className="text-right">
                  <Form.Check type="checkbox" checked={showActiveRight} onChange={e => setShowActiveRight(e.target.checked)}/>
                  <Form.Check type="checkbox" checked={showProposedRight} onChange={e => setShowProposedRight(e.target.checked)}/>
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} controlId="tlcont.FilterDisplayChecks2">
                <Col>
                  <Form.Check type="checkbox" checked={showJetLeft} onChange={e => setShowJetLeft(e.target.checked)}/>
                  <Form.Check type="checkbox" checked={showTurboLeft} onChange={e => setShowTurboLeft(e.target.checked)} />
                  <Form.Check type="checkbox" checked={showPropLeft} onChange={e => setShowPropLeft(e.target.checked)} />
                </Col>
                <Col className="text-center" lg={6}>
                  Jet<br />
                  Turboprop<br/>
                  Prop
                </Col>
                <Col className="text-right">
                  <Form.Check type="checkbox" checked={showJetRight} onChange={e => setShowJetRight(e.target.checked)} />
                  <Form.Check type="checkbox" checked={showTurboRight} onChange={e => setShowTurboRight(e.target.checked)}/>
                  <Form.Check type="checkbox" checked={showPropRight} onChange={e => setShowPropRight(e.target.checked)} />
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} controlId="tlcont.FilterDisplayChecks2">
                <Col>
                  <Form.Check type="checkbox" checked={showVFRLeft} onChange={e => setShowVFRLeft(e.target.checked)}/>
                </Col>
                <Col className="text-center" lg={6}>
                  VFR Flights
                </Col>
                <Col className="text-right">
                  <Form.Check type="checkbox" checked={showVFRRight} onChange={e => setShowVFRRight(e.target.checked)} />
                </Col>
              </Form.Group>
            </Container>
            <hr />
            <Container className="text-center">
            <ButtonGroup className="mb-2">
                <Button type="submit">Apply</Button>
                <Button type="submit" onClick={props.close_func} variant="success">Save and Close</Button>
              </ButtonGroup>
            </Container>  
          </Form>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default TMUModal;