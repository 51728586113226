import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

// React router
import { BrowserRouter } from 'react-router-dom';
//import { TMUAPI, API } from './context';

// Require PureCSS... must come after 
// imports or error
// require('wingcss');
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/custom.css';

ReactDOM.render(
  //<React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>,
  //</React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
